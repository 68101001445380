import React from 'react'
import clsx from 'clsx'
import WarningIcon from '@mui/icons-material/Warning'

export type ErrorListItemProps = {
	property?: string
	propertyName?: string
	error?: string
	errorMessage?: string
	message?: string
}

export type ErrorListProps = {
	title?: string
	errors?: ErrorListItemProps[]
	className?: string
}

const ErrorList = ({ title, errors, className }: ErrorListProps) => {
	if (!errors || errors.length === 0) return null

	const errorsWithUuid = errors.map((error) => {
		return {
			...error,
			uuid: crypto.randomUUID(),
		}
	})
	return (
		<div className={clsx('bg-red-100 p-3 rounded', className)}>
			{title && <h2 className="text-red-500 text-lg">{title}</h2>}
			<div className="flex items-center">
				<WarningIcon className="text-red-700 mr-2" />
				<ul>
					{errorsWithUuid?.map((error) => (
						<li
							key={error.uuid}
							className="text-red-700 text-sm font-bold"
						>
							{error.property ||
								(error.propertyName && (
									<strong>
										{error.property || error.propertyName}
									</strong>
								))}{' '}
							{error.message || error.error || error.errorMessage}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default ErrorList
