import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import { useVenti } from 'venti'

import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import withAuth from '../../../components/withAuth'
import CorporationExpandedRow from './CorporationExpandedRow'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import { navigationLinking } from '../../../services/navigation'
import queryKeys from '../../../services/queryKeys'

import { useAlert, useWindowSize } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { getTheme } from '../../../config'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'

const theme = getTheme()

const AdminCorporations = () => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const [width] = useWindowSize()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const [corporatesData, setCorporatesData] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()
	const [searchText, setSearchText] = useState('')
	const [filteredData, setFilteredData] = useState([])
	const [filters, setFilters] = useState({
		active: 'active',
	})

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'NMLS ID',
			selector: (row) => row.nmlsid,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
		},
		{
			name: 'URL',
			selector: (row) => row.url,
		},
		{
			name: 'Created',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active/Deleted',
			selector: (row) => (row.deletedAt ? 'Deleted' : 'Active'),
			sortable: true,
		},
	]

	const actionItems = [
		{
			name: 'Edit',
			onClick: (e, row) => onRowClicked(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Restore',
			onClick: (e, row) => showRestoreModal(row),
			hideIf: (row) => row.deletedAt === null,
		},
	]

	const {
		isFetching,
		isRefetching,
		error: errorFetchingCorporations,
		data: corporationsResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.corporations],
		queryFn: () => TheBigPOSApi.getCorporates({ showAll: true }),
	})

	useEffect(() => {
		if (errorFetchingCorporations) {
			alert('There was a problem loading corporations', {
				severity: 'error',
			})
		}
	}, [errorFetchingCorporations])

	useEffect(() => {
		if (corporationsResult?.data) {
			const corporations = []
			corporationsResult.data.rows.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					// id is being removed, so replaced with ID
					// will figure out later why
					corporations.push({
						...site,
						ID: site.id,
						corporateId: item.id,
						createdAt: item.createdAt,
						deletedAt: item.deletedAt,
					})
				})
			})
			setCorporatesData(corporations)
		}
	}, [corporationsResult])

	useEffect(() => {
		if (searchText !== '' || filters.active !== 'all') {
			const lowercasedSearchText = searchText.toLowerCase()
			let newFilteredData = !searchText
				? corporatesData
				: corporatesData?.filter(
						(d) =>
							d.name?.toLowerCase().includes(lowercasedSearchText) ||
							d.url?.toLowerCase().includes(lowercasedSearchText) ||
							d.nmlsid?.toString().includes(lowercasedSearchText)
					)
			newFilteredData = newFilteredData.filter((d) => {
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				return true
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(corporatesData)
		}
	}, [corporatesData, searchText, filters])

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [
				queryKeys.corporations,
				queryKeys.corporationSiteConfiguration,
			],
		})
		await refetch()
	}

	const handleMutationSuccess = (
		eventType,
		modalSetter,
		activeRow,
		successMessage
	) => {
		const { id, name } = activeRow
		mixpanel.trackEvent(eventType, {
			id,
			name,
		})
		modalSetter(false)
		alert(`Brand "${name}" successfully ${successMessage}`)
	}

	const restoreCorporateMutation = useMutation({
		mutationFn: (id) => TheBigPOSApi.restoreCorporate(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.BRAND_RESTORED,
				setRestoreModalVisible,
				rowToRestore,
				'restored'
			)
			setRowToRestore(null)
		},
	})

	const removeCorporateMutation = useMutation({
		mutationFn: (id) => TheBigPOSApi.deleteCorporate(id),
		onSuccess: () => {
			invalidateQueries()
			handleMutationSuccess(
				eventTypes.BRAND_DELETED,
				setRemoveModalVisible,
				rowToRemove,
				'removed'
			)
			setRowToRemove(null)
		},
	})

	const removeCorporate = async () => {
		try {
			if (rowToRemove?.corporateId)
				await removeCorporateMutation.mutateAsync(
					rowToRemove?.corporateId
				)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const restoreCorporate = async () => {
		try {
			if (rowToRestore?.corporateId)
				await restoreCorporateMutation.mutateAsync(
					rowToRestore?.corporateId
				)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const onAddClick = () => {
		navigate(
			generatePath(`/${navigationLinking.AdminCorporateEdit}`, {
				id: 'new',
			})
		)
	}

	const onRowClicked = async (row) => {
		await ventiState.set(
			theme.storageKeys.editingCorporateSiteConfigurationId,
			row.id
		)
		navigate(
			generatePath(`/${navigationLinking.AdminCorporateEdit}`, {
				id: row?.corporateId,
			})
		)
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	const onClearSearchClick = () => {
		setSearchText('')
	}

	return (
		<Page title="Brands" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeCorporate}
					row={rowToRemove}
					loading={removeCorporateMutation.isPending}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreCorporate}
					row={rowToRestore}
					loading={restoreCorporateMutation.isPending}
				/>
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={isFetching || isRefetching}
					title={'Brands'}
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					searchText={searchText}
					onClearSearchClick={onClearSearchClick}
					fixedHeader={true}
					exportEnabled={true}
					expandableRowsComponent={CorporationExpandedRow}
					actionItems={actionItems}
					searchFilters={
						<div
							className={`flex w-full ${width < 768 && 'flex-col'} space-between`}
						>
							{' '}
							<FormControl
								id="filterActive"
								variant="standard"
								sx={[
									width < 768
										? { width: 256 }
										: { width: 300, marginRight: 3 },
								]}
							>
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
						</div>
					}
					addNewBtn={{
						text: 'Add Brand',
						onClick: onAddClick,
					}}
				/>
			</div>
		</Page>
	)
}
export default withAuth(AdminCorporations)
