import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { isEqual } from 'lodash'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import { useVenti } from 'venti'
import { Tabs, Tab, Card, CardContent } from '@mui/material'
import Page from '../../../components/Page'
import { LoadingBtn } from '../../../components/Button'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { ModalError } from '../../../components/modals/ModalError'
import { useAppContext } from '../../../components/AppContext'
import { Loading } from '../../../components/Loading'
import { Features } from '../../../components/admin/Features'
import { WebsiteSettings } from '../../../components/admin/WebsiteSettings'
import { Integrations } from '../../../components/admin/Integrations'
import ProfileView from './Profile'

import { navigationLinking } from '../../../services/navigation'
import { siteConfigurationModelWithNulls } from '../../../services/utils'
import {
	a11yProps,
	isEmailValid,
	getErrorMessage,
	validateSiteConfigurationData,
	isValidNMLSId,
} from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import queryKeys from '../../../services/queryKeys'

import { useAlert, useWindowSize } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { getTheme } from '../../../config'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

export default function AdminLoanOfficerEdit({ navigation }) {
	const { alert } = useAlert()
	const { id } = useParams()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const { siteConfig } = state
	const ventiState = useVenti()
	const queryClient = useQueryClient()
	const [width] = useWindowSize()
	const themeMUI = useTheme()
	const mixpanel = useMixpanel()
	const isCreateNewLoanOfficer = id === 'new'
	const siteConfigurationId = ventiState.get(
		theme.storageKeys.editingLoanOfficerSiteConfigurationId
	)

	const [activeTab, setActiveTab] = useState(0)

	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [initialState, setInitialState] = useState({
		...siteConfigurationModelWithNulls,
		entityType: 3,
	})
	const [formData, setFormData] = useState({
		...siteConfigurationModelWithNulls,
		entityType: 3,
	})

	const [inheritedSiteConfig, setInheritedSiteConfig] = useState({})
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const [password, setPassword] = useState()
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const initialRender = useRef(true)

	const { error: errorFetchingLoanOfficer, data: loanOfficerResult } =
		useQuery({
			queryKey: [queryKeys.loanOfficer, id],
			queryFn: () => TheBigPOSApi.getLoanOfficer(id),
			enabled: !!id && !isCreateNewLoanOfficer,
		})

	const {
		error: errorFetchingLoanOfficerSiteConfiguration,
		data: loanOfficerSiteConfigurationResult,
	} = useQuery({
		queryKey: [
			queryKeys.loanOfficerSiteConfiguration,
			siteConfigurationId,
		],
		queryFn: () =>
			TheBigPOSApi.getLoanOfficerSiteConfiguration(
				id,
				siteConfigurationId
			),
		enabled: !!id && !isCreateNewLoanOfficer && !!siteConfigurationId,
	})

	useEffect(() => {
		if (!id) navigate(`/${navigationLinking.AdminLoanOfficer}`)
	}, [id])

	useEffect(() => {
		if (errorFetchingLoanOfficer) {
			alert('There was a problem loading the loan officer', {
				severity: 'error',
			})
		}
	}, [errorFetchingLoanOfficer])

	useEffect(() => {
		if (errorFetchingLoanOfficerSiteConfiguration) {
			alert(
				'There was a problem loading the loan officer site configuration',
				{
					severity: 'error',
				}
			)
		}
	}, [errorFetchingLoanOfficerSiteConfiguration])

	useEffect(() => {
		const { domain, ...rest } = formData
		const canBeSubmitted =
			formData?.url?.length > 0 &&
			isEmailValid(formData?.email?.trim()) &&
			formData?.phone?.replace(/\D/g, '').length === 10 &&
			formData?.firstName?.trim().length > 0 &&
			formData?.lastName?.trim().length > 0 &&
			formData?.title?.trim().length > 0 &&
			formData?.licenses?.length > 0 &&
			isValidNMLSId(formData?.nmlsid) &&
			!isEqual(initialState, rest)

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (
			loanOfficerResult?.data &&
			loanOfficerSiteConfigurationResult?.data
		) {
			const loData = {
				...loanOfficerSiteConfigurationResult.data.siteConfiguration,
				branchID: loanOfficerResult.data.branchID,
				firstName: loanOfficerResult.data.firstName,
				lastName: loanOfficerResult.data.lastName,
				title: loanOfficerResult.data.title,
			}
			setFormData(loData)
			setInheritedSiteConfig(
				loanOfficerSiteConfigurationResult.data
					.inheritedSiteConfiguration
			)
			setInitialState(loData)
		}
	}, [loanOfficerResult, loanOfficerSiteConfigurationResult])

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.loanOfficers, queryKeys.loanOfficer],
		})
	}

	const invalidateSiteConfigurationQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [
				queryKeys.loanOfficerSiteConfiguration,
				siteConfigurationId,
			],
		})
	}

	const updateLoanOfficerMutation = useMutation({
		mutationFn: (data) => TheBigPOSApi.replaceUser(data.id, data),
		onSuccess: invalidateQueries,
	})

	const createLoanOfficerMutation = useMutation({
		mutationFn: (data) => TheBigPOSApi.createUser(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanOfficers],
			})
		},
	})

	const updateLoanOfficerSiteConfigurationMutation = useMutation({
		mutationFn: ({ data, loanOfficerId, siteConfigurationId }) =>
			TheBigPOSApi.replaceLoanOfficerSiteConfiguration(
				loanOfficerId,
				siteConfigurationId,
				data
			),
		onSuccess: invalidateSiteConfigurationQueries,
	})

	const createLoanOfficerSiteConfigurationMutation = useMutation({
		mutationFn: ({ data, loanOfficerId }) =>
			TheBigPOSApi.createLoanOfficerSiteConfiguration(
				loanOfficerId,
				data
			),
	})

	const saveForm = async () => {
		// Check the URL is the correct format and matches the domain
		const parts = formData.url.split('.')
		if (parts.length !== 3) {
			setErrorMsg(
				`The format of the URL you've entered is not valid. It should appear like loanofficer.${formData.domain}`
			)
			setErrorModalVisible(true)
			return
		}

		const subdomain = parts.shift()
		if (parts.join('.') !== formData.domain) {
			setErrorMsg(
				`The domain you've entered does not match the domain of the branch. It should appear like ${subdomain}.${formData.domain}`
			)
			setErrorModalVisible(true)
			return
		}

		try {
			const {
				id: formId,
				name,
				email,
				phone,
				firstName,
				lastName,
				title,
				branchID,
				entityID,
			} = formData
			if (formId) {
				await updateLoanOfficerMutation.mutateAsync({
					id: entityID,
					email,
					phone: `+1${phone.replace(/\D/g, '')}`,
					firstName,
					lastName,
					title,
					branchId: branchID,
				})
				await updateLoanOfficerSiteConfigurationMutation.mutateAsync({
					data: formData,
					loanOfficerId: entityID,
					siteConfigurationId: formId,
				})
				mixpanel.trackEvent(eventTypes.LOAN_OFFICER_UPDATED, {
					name,
					id: formId,
				})

				alert(`Loan Officer "${name}" successfully updated`)
				navigate(`/${navigationLinking.AdminLoanOfficer}`)
			} else {
				const loanOfficerResponse =
					await createLoanOfficerMutation.mutateAsync({
						email,
						phone: `+1${phone.replace(/\D/g, '')}`,
						firstName,
						lastName,
						title,
						branchId: branchID,
						userRole: 'LoanOfficer',
					})
				const loanOfficer = loanOfficerResponse?.data

				validateSiteConfigurationData(formData)

				if (loanOfficer) {
					const { id: loId } = loanOfficer
					await createLoanOfficerSiteConfigurationMutation.mutateAsync(
						{
							data: formData,
							loanOfficerId: loId,
						}
					)
					mixpanel.trackEvent(eventTypes.LOAN_OFFICER_CREATED, {
						name,
						id: loId,
					})
					alert(`Loan Officer "${name}" successfully created`)
					navigate(`/${navigationLinking.AdminLoanOfficer}`)
				}
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleTabChange = (event, newValue) => {
		if (newValue !== 0 && !formData.branchID) {
			setErrorMsg('Please select a branch.')
			setErrorModalVisible(true)
			return
		}
		setActiveTab(newValue)
	}
	const handleChangeIndex = (index) => {
		setActiveTab(index)
	}

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0
			? setModalConfirmation({
					open: true,
					text: theme.modal.licensesText,
					leavePage: false,
					canBeSaved: false,
					isSave: true,
					event: null,
				})
			: saveForm()
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})
	}

	return (
		<Page
			isFullWidth
			title={
				isCreateNewLoanOfficer
					? `New Loan Officer`
					: 'Edit Loan Officer'
			}
		>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<ModalError
				visible={errorModalVisible}
				setVisible={setErrorModalVisible}
				message={errorMsg}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					{isCreateNewLoanOfficer
						? `New Loan Officer`
						: 'Edit Loan Officer'}
				</p>
				{!isCreateNewLoanOfficer && !initialState.id ? (
					<div
						className="flex justify-center dark:bg-[#121212]"
						style={{ height: `calc(100vh - 216px)` }}
					>
						<Loading size="small" />
					</div>
				) : (
					<>
						<div className="flex flex-wrap justify-end my-5">
							<LoadingBtn
								id="AdminLoanOfficerEditSaveButton"
								disabled={!canBeSubmitted}
								loading={
									updateLoanOfficerSiteConfigurationMutation.isPending ||
									createLoanOfficerSiteConfigurationMutation.isPending
								}
								text={`${isCreateNewLoanOfficer ? 'Create Loan Officer' : 'Update Loan Officer'}`}
								onClick={handleCheckFormLicenses}
								fullWidth={false}
							/>
						</div>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={activeTab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							<Tab label="LO Profile" {...a11yProps(0)} />
							<Tab label="Branding & Images" {...a11yProps(1)} />
							<Tab label="Features" {...a11yProps(2)} />
							<Tab label="Integrations" {...a11yProps(3)} />
						</Tabs>
						<SwipeableViews
							axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={activeTab}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel
								value={activeTab}
								index={0}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<ProfileView
											siteConfig={siteConfig}
											inheritedSiteConfiguration={inheritedSiteConfig}
											setInheritedSiteConfiguration={
												setInheritedSiteConfig
											}
											formData={formData}
											setFormData={setFormData}
											width={width}
											setPassword={setPassword}
											password={password}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={activeTab}
								index={1}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<WebsiteSettings
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											entityType={3}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={activeTab}
								index={2}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Features
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={activeTab}
								index={3}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Integrations
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											entityType={3}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
						</SwipeableViews>
					</>
				)}
			</div>
		</Page>
	)
}
