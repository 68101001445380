import React from 'react'
import {
	DropzoneField,
	Form,
	FormRow,
} from '../../../../components/formControls'
import useForm from '../../../../hooks/useForm'
import { Button } from '@mui/material'
import { LoadingBtn } from '../../../../components/Button'
import { fileMBSizes } from '../../../../services/utils'
import * as yup from 'yup'

const LoanTaskDocumentForm = ({ onSubmit, onCancel = undefined }) => {
	const schema = yup.object().shape({
		documents: yup
			.array()
			.min(1)
			.required('At least 1 document is required'),
	})

	const form = useForm({
		schema,
		values: {},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
	} = form

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<FormRow>
				<DropzoneField
					name="documents"
					accept={{
						'': [
							'image/jpeg',
							'image/png',
							'image/gif',
							'image/webp',
							'.pdf',
							'.doc',
							'.docx',
							'.rtf',
						],
					}}
					helperText="Upload PDF, Image (.jpeg .png) or Document (.doc .docx .rtf) files"
					control={control}
					errors={errors}
					minSize={0}
					maxSize={fileMBSizes[20]}
					maxFiles={10}
				/>
			</FormRow>
			<FormRow className="flex flex-row justify-between items-center mt-6">
				{onCancel && (
					<div className="mr-2">
						<Button id="Cancel" onClick={onCancel} variant="outlined">
							Cancel
						</Button>
					</div>
				)}
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
					fullWidth={false}
				/>
			</FormRow>
		</Form>
	)
}

export default LoanTaskDocumentForm
