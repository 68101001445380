import React, { useCallback, useEffect, useMemo } from 'react'
import * as yup from 'yup'
import { Button } from '@mui/material'
import moment from 'moment'
import type { Task, User } from '@matech/thebigpos-sdk'
import type { FieldValues, SubmitHandler } from 'react-hook-form'
import Form from '../components/formControls/Form'
import useForm from '../hooks/useForm'
import Select from '../components/formControls/Select'
import {
	Checkbox,
	FormRow,
	TextField,
	DatePicker,
} from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import useUser from '../hooks/useUser'
import TaskLosTargetField from '../components/formControls/TaskLosTargetField'
import NumberField from '../components/formControls/NumberField'
import type { LabelValuePair } from '../types'
import type { ErrorListItemProps } from '../components/ErrorList'

export interface Values extends FieldValues {
	name: string
	description: string
	type: string
	daysDueFromApplication: number | null
	dueDate: moment.Moment | null
	targetUserRole: string
	isGlobal: boolean
	losTarget: string
	willAutocompleteAfterResponse: boolean
	hasAutoPropagationOnAdd: boolean
	borrowers: User[]
}

export type TaskFormProps = {
	task?: Task
	onSubmit: SubmitHandler<Values>
	onCancel?: () => void
	apiErrors?: ErrorListItemProps[]
	loanId?: string
	borrowers?: User[]
	isGlobalDisabled?: boolean
}

const TaskForm = ({
	task,
	onSubmit,
	onCancel,
	apiErrors = [],
	loanId,
	borrowers = [],
	isGlobalDisabled = false,
}: TaskFormProps) => {
	const { isAdmin } = useUser()

	const borrowerSelectEnabled = loanId && borrowers.length > 0

	const schema = yup.object().shape({
		name: yup.string().required('Name is required'),
		type: yup.string().required('Type is required'),
		daysDueFromApplication: yup
			.number()
			.min(0)
			.max(60)
			.required('Task Duration is required'),
		targetUserRole: borrowerSelectEnabled
			? yup.string().nullable()
			: yup.string().required('User role is required'),
		// @ts-ignore @todo fix type error with yup when condition. not liking is: null
		borrowers: yup.array().when('targetUserRole', {
			is: null,
			then: yup
				.array()
				.min(1)
				.required('At least 1 borrower is required'),
		}),
	})

	const values: Values = useMemo(() => {
		return {
			name: task?.name || '',
			description: task?.description || '',
			type: task?.type || '',
			daysDueFromApplication:
				typeof task?.daysDueFromApplication === 'number'
					? task?.daysDueFromApplication
					: null,
			dueDate: task
				? moment(task.createdAt).add(
						task.daysDueFromApplication,
						'day'
					)
				: null,
			targetUserRole: task?.targetUserRole || '',
			isGlobal: task?.isGlobal || false,
			losTarget: task?.losTarget || '',
			willAutocompleteAfterResponse:
				task?.willAutocompleteAfterResponse || false,
			hasAutoPropagationOnAdd: task?.hasAutoPropagationOnAdd || false,
			borrowers: [],
		}
	}, [task])

	const form = useForm<Values>({
		schema,
		values,
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		watch,
		setValue,
	} = form

	const typeOptions: LabelValuePair[] = useMemo(
		() => [
			{ value: 'Document', label: 'Provide a Document' },
			{ value: 'Field', label: 'Request Information' },
			{
				value: 'VerificationOfAssets',
				label: 'Verify Assets (VOA)',
			},
			{
				value: 'VerificationOfIncome',
				label: 'Verify Income (VOI)',
			},
			{
				value: 'VerificationOfEmployment',
				label: 'Verify Employment (VOE)',
			},
			{
				value: 'VerificationOfIncomeAndEmployment',
				label: 'Verify Income & Employment (VOIE)',
			},
		],
		[]
	)

	const userRoleOptions: LabelValuePair[] = [
		{
			value: 'Borrowers',
			label: 'All Borrowers',
		},
		{
			value: 'Borrower',
			label: 'Borrower',
		},
		{
			value: 'CoBorrower',
			label: 'Co-Borrower',
		},
	]

	const type = watch('type')
	const daysDueFromApplication = watch('daysDueFromApplication')
	const dueDate = watch('dueDate')

	useEffect(() => {
		const target = task?.losTarget
		setValue('losTarget', target || '')
	}, [setValue, task?.losTarget, type, typeOptions])

	const borrowerOptions = useMemo(() => {
		return borrowers.map((borrower) => ({
			value: borrower.id,
			label: `${borrower.firstName} ${borrower.lastName}`,
			role: borrower.role,
		}))
	}, [borrowers])

	const getBorrowerOptionByRole = useCallback(
		(role: string) => {
			return borrowerOptions.find((b) => b.role === role)
		},
		[borrowerOptions]
	)

	useEffect(() => {
		if (!isAdmin && dueDate) {
			const daysDue = moment(dueDate).diff(moment(), 'day', true)
			setValue('daysDueFromApplication', Math.ceil(daysDue))
		}
	}, [daysDueFromApplication, dueDate, isAdmin, setValue])

	useEffect(() => {
		if (borrowerOptions.length === 0) return

		// pre-populate the borrowers field
		if (borrowerOptions.length === 1) {
			// @ts-ignore @todo fix me
			setValue('borrowers', [borrowerOptions[0].value]) // default to only borrower
		} else if (task?.targetUserRole) {
			// add borrowers based on targetUserRole
			const borrowerOptionsFromTargetRole = []
			// eslint-disable-next-line default-case
			switch (task?.targetUserRole) {
				case 'Borrowers':
					borrowerOptions.forEach((borrower) => {
						borrowerOptionsFromTargetRole.push(borrower.value)
					})
					break
				case 'Borrower':
					const borrower = getBorrowerOptionByRole('Borrower')
					if (borrower) {
						borrowerOptionsFromTargetRole.push(borrower.value)
					}
					break
				case 'CoBorrower':
					const coBorrower = getBorrowerOptionByRole('CoBorrower')
					if (coBorrower) {
						borrowerOptionsFromTargetRole.push(coBorrower.value)
					}
					break
			}
			if (borrowerOptionsFromTargetRole.length > 0) {
				// @ts-ignore @todo fix me
				setValue('borrowers', borrowerOptionsFromTargetRole)
			}
		}
	}, [borrowerOptions, getBorrowerOptionByRole, setValue, task])

	return (
		<Form onSubmit={handleSubmit(onSubmit)} errors={apiErrors}>
			<FormRow>
				<TextField
					name="name"
					label="Question"
					helperText={`What ${type === 'Document' ? 'document' : 'information'} would you like the user to provide?`}
					className="w-full"
					required
					control={control}
					errors={errors}
				/>
			</FormRow>
			<FormRow>
				<TextField
					name="description"
					label="Description"
					helperText="Provide a user facing description explaining why this task is required"
					className="w-full"
					multiline
					rows={3}
					control={control}
					errors={errors}
				/>
			</FormRow>
			<FormRow className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
					<Select
						name="type"
						className="w-full"
						label="Type"
						helperText="Type of task (e.g. Question, Document)"
						menuItems={typeOptions}
						required
						control={control}
						errors={errors}
					/>
				</div>
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm md:pl-3">
					<TaskLosTargetField
						name="losTarget"
						loanId={loanId}
						type={type}
						control={control}
						errors={errors}
						required={type !== 'Field'}
					/>
				</div>
			</FormRow>
			<FormRow className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
					{borrowerSelectEnabled ? (
						<Select
							name="borrowers"
							menuItems={borrowerOptions}
							label={`Borrower${borrowerOptions.length > 1 ? 's' : ''}`}
							fullWidth
							required
							control={control}
							errors={errors}
							selectProps={{ multiple: true }}
							disabled={!!(task?.id || borrowerOptions.length === 1)}
						/>
					) : (
						<Select
							name="targetUserRole"
							menuItems={userRoleOptions}
							label="User Role"
							fullWidth
							required
							disabled={!!(task?.id && !isAdmin)}
							control={control}
							errors={errors}
						/>
					)}
				</div>
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm md:pl-3">
					{isAdmin ? (
						<NumberField
							name="daysDueFromApplication"
							label="Due Date"
							className="w-full"
							helperText="Number of days until task is due after it's created. 0 Days is due immediately"
							required
							control={control}
							errors={errors}
						/>
					) : (
						<DatePicker
							name="dueDate"
							label="Due Date"
							className="w-full"
							required
							control={control}
							errors={errors}
							minDate={moment()}
						/>
					)}
				</div>
			</FormRow>
			<FormRow>
				<Checkbox
					name="willAutocompleteAfterResponse"
					label="Auto Complete"
					control={control}
					errors={errors}
					helperText="Task status will be set to complete when the task is submitted"
				/>
			</FormRow>
			{isAdmin && (
				<>
					{/* <FormRow>
						<Checkbox
							name="hasAutoPropagationOnAdd"
							label="Auto Add"
							control={control}
							errors={errors}
							helperText="Automatically add task when updates to the loan meet the rule conditions"
						/>
					</FormRow> */}
					<FormRow>
						<Checkbox
							name="isGlobal"
							label="Global"
							control={control}
							errors={errors}
							disabled={isGlobalDisabled}
							helperText="Should this task be shared between business rules and
                        loans?"
						/>
					</FormRow>
				</>
			)}
			<FormRow className="flex flex-row justify-between items-center mt-3">
				{onCancel && (
					<div className="mr-2">
						<Button id="Cancel" onClick={onCancel} variant="outlined">
							Cancel
						</Button>
					</div>
				)}
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
					fullWidth={false}
				/>
			</FormRow>
		</Form>
	)
}

export default TaskForm
