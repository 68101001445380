import React, { useMemo, useState } from 'react'
import Handlebars from 'handlebars'
import ErrorList from './ErrorList'

type HandlebarsPreviewProps = {
	template: string
	data: string
	className?: string
	title?: string
}

const HandlebarsPreview = ({
	template,
	data,
	className,
	title = 'Template preview',
}: HandlebarsPreviewProps) => {
	const [error, setError] = useState<Error | unknown>(null)

	// eslint-disable-next-line consistent-return
	const parsedHtml = useMemo(() => {
		try {
			const handlebarsTemplate = Handlebars.compile(template)
			setError(null)
			return handlebarsTemplate(data)
		} catch (e) {
			setError(e)
		}
	}, [template, data])

	return (
		<>
			<ErrorList errors={error ? [error] : []} />
			<iframe
				className={className}
				title={title}
				srcDoc={parsedHtml}
			/>
		</>
	)
}

export default HandlebarsPreview
