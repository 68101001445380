import React, { useState } from 'react'
import { Step, StepLabel, Stepper } from '@mui/material'
import MobilePhoneForm from '../forms/MobilePhoneForm'
import AuthCodeForm from '../forms/AuthCodeForm'
import { getErrorMessage } from '../services/helper'
import useUser from '../hooks/useUser'
import ResendVerificationCodeLink from './ResendVerificationCodeLink'
import { formatPhoneWithCountryCode } from '../services/formattingUtils'
import { TheBigPOSApi } from '../lib/TheBigPOSClient'
import { useAlert } from '../hooks'

const MobilePhoneVerificationStepper = ({
	className,
	onComplete,
}) => {
	const { user, updateMe, updateMyPhone } = useUser()
	const { alert } = useAlert()

	const [loading, setLoading] = useState(false)
	const [activeStep, setActiveStep] = useState(0)
	const [error, setError] = useState(null)

	const onPhoneLabelClick = () => {
		setActiveStep(0)
	}

	const onPhoneSubmit = async (phone) => {
		const formattedPhone = formatPhoneWithCountryCode(phone)
		if (formattedPhone === user.phone && user.mfaEnabled) {
			onComplete() // exit if using the same phone number
			return
		}

		try {
			setLoading(true)
			await updateMyPhone(formattedPhone) // will trigger cognito to send SMS
			await setActiveStep(activeStep + 1)
		} catch (e) {
			setError({ message: getErrorMessage(e) })
		} finally {
			setLoading(false)
		}
	}

	const onCodeSubmit = async (data) => {
		try {
			await TheBigPOSApi.verifyUserMobilePhone(data)
			await updateMe({
				...user,
				mfaEnabled: true,
			})
			alert('Your MFA settings have been updated', {
				severity: 'success',
			})
			onComplete()
		} catch (e) {
			setError({
				message: e.data.message ? e.data.message : getErrorMessage(e),
			})
		}
	}

	return (
		<div className={className}>
			<Stepper activeStep={activeStep}>
				<Step completed={activeStep > 0}>
					<StepLabel
						onClick={onPhoneLabelClick}
						className={activeStep > 0 ? 'cursor-pointer' : undefined}
					>
						Phone
					</StepLabel>
				</Step>
				<Step>
					<StepLabel>Code</StepLabel>
				</Step>
			</Stepper>
			<div className="mt-5">
				{activeStep === 0 && (
					<>
						<p className="text-md sm:text-1xl font-bold mb-6 text-red dark:text-white text-center">
							Please enter your mobile phone number you would like to
							use for MFA.
						</p>
						<MobilePhoneForm
							onSubmit={onPhoneSubmit}
							loading={loading}
						/>
					</>
				)}
				{activeStep === 1 && (
					<div>
						<p className="text-md sm:text-1xl font-bold mb-6 text-red dark:text-white text-center">
							Please check your mobile phone for a code
						</p>
						<AuthCodeForm
							onSubmit={onCodeSubmit}
							errors={error ? [error] : []}
						/>
						<div className="mt-2 flex items-center">
							<ResendVerificationCodeLink />
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default MobilePhoneVerificationStepper
