import React, { useEffect, useState, useRef, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import Page from '../../components/Page'
import { Footer } from '../../components/Footer'
import { CardItem } from '../../components/CardItem'
import { Loading } from '../../components/Loading'
import { useAppContext } from '../../components/AppContext'
import { useAlert, useWindowSize } from '../../hooks'
import { getTheme } from '../../config'
import { ModalAppError } from '../../components/modals/ModalAppError'
import { ModalTutorial } from '../../components/modals/ModalTutorial'
import { ModalAddCoBorrower } from '../../components/modals/ModalAddCoBorrower'
import { ModalPleaseWait } from '../../components/modals/ModalPleaseWait'
import { ModalWizard } from '../../components/modals/ModalWizard'
import { ModalLoanAppSelector } from '../../components/modals/ModalLoanAppSelector'
import inviteCoBorrowerQuestions from '../../workflows/portal-invite-coborrower-questions.json'
import { Roles } from '../../services/client'
import { getErrorMessage, inviteActions } from '../../services/helper'
import {
	navigationLinking,
	getActionsByRole,
} from '../../services/navigation'
import userAvatar from 'assets/images/landingPages/user-avatar.png'
import { ModalNewUser } from '../../components/modals/ModalNewUser'
import Typography from '@mui/material/Typography'
import { routeTypes } from '../loanapp/LoanApp'
import { ModalYesNo } from '../../components/modals/ModalYesNo'
import { useAppContextActions } from '../../components/AppContext/AppHooks'
import { eventTypes } from '../../services/constants'
import { useMixpanel } from '../../hooks/useMixpanel'
import { disclosuresSSOSignIn } from '../../services/utils'
import { ModalWorkflowSelector } from '../../components/modals/ModalWorkflowSelector'
import useUser from '../../hooks/useUser'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'

const theme = getTheme()
const middleScreen = 768

export default function Portal() {
	const { alert } = useAlert()
	const mixpanel = useMixpanel()
	const mountedRef = useRef(true)
	const navigate = useNavigate()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { applyUser } = useAppContextActions()
	const {
		siteConfig,
		socialLinks,
		legalLinks,
		user,
		invite,
		impersonationRequest,
		authToken,
	} = state
	const [width] = useWindowSize()
	const { canUseTasks, isAdmin } = useUser()

	const loanId = ventiState.get(theme.storageKeys.loanId) || ''
	const appSubmitted = ventiState.get(theme.storageKeys.appSubmitted)
	const appPosting = ventiState.get(theme.storageKeys.appPosting)
	const inviteCoBorrower = ventiState.get(
		theme.storageKeys.inviteCoBorrower
	)
	const appFailed = ventiState.get(theme.storageKeys.appFailed)
	const failoverModalVisible = ventiState.get(
		theme.storageKeys.failoverModalVisible
	)
	const newUser = ventiState.get(theme.storageKeys.newUser)
	const ventiLoanData = ventiState.get(theme.storageKeys.loanData)
	const coBorrowerRoute = ventiState.get(
		theme.storageKeys.coBorrowerRoute
	)
	const isFirstLoad = !ventiState.get(theme.storageKeys.isFirstLoad)
	const loanOfficer =
		ventiState.get(theme.storageKeys.loanOfficer) || {}
	const [isLogoUrlValid, setIsLogoUrlValid] = useState(true)
	const [actions, setActions] = useState([])
	const [inviteData, setInviteData] = useState({})
	const [loanData, setLoanData] = useState(ventiLoanData || {})
	const [loanError, setLoanError] = useState(false)
	const [fetchingApplications, setFetchingApplications] =
		useState(false)
	const [fetchingApplication, setFetchingApplication] =
		useState(false)
	const [fetchingDisclosureInfo] = useState(false)
	const [loanApplications, setLoanApplications] = useState([])
	const [addCoBorrower, setAddCoBorrower] = useState(false)
	const [draftModalConfirmation, setDraftModalConfirmation] =
		useState({
			open: false,
			text: '',
			leavePage: false,
			canBeSaved: false,
			isSave: false,
			event: null,
		})
	const [loanIdModalVisible, setLoanIdModalVisible] = useState(false)
	const [consentQuestions, setConsentQuestions] = useState([])
	const [tutorialVisible, setTutorialVisible] = useState(false)
	const [modalWizard, setModalWizard] = useState({
		visible: inviteCoBorrower || false,
		data: {},
		title: '',
	})

	const [outstandingTaskCount, setOutstandingTaskCount] = useState(0)
	const [
		languageSelectorModalActions,
		setLanguageSelectorModalActions,
	] = useState([])

	const _getPageActions = (actions) => {
		if (window.innerWidth < middleScreen)
			actions = actions.filter((item) => item.id !== 'Workflows')

		return actions
	}

	const sendInvitation = async (data) => {
		data.sourceURL = `https://${siteConfig.url}/verify/invite`
		data.siteConfigurationID = siteConfig.id

		if (loanId) {
			data.loanID = loanId

			closeInviteModal()

			try {
				await TheBigPOSApi.inviteUser(data)
				alert('The invitation was sent successfully')
				ventiState.set(
					theme.storageKeys.successMessage,
					'The invitation was sent successfully.'
				)
			} catch (err) {
				alert(getErrorMessage(err), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					'An error occurred while attempting to send the invitation. Please try again later.'
				)
			}
		} else {
			setInviteData(data)
		}
	}

	const handleDuplicateLoan = async () => {
		navigate(`/${navigationLinking.Apply}`)
	}

	const handleDisclosures = async () => {
		await disclosuresSSOSignIn(siteConfig, authToken)
	}

	const handleOpenPayment = () => {
		if (siteConfig.servicerLenderId || true)
			return window.open(
				`https://pay.finigree.com/loanPayment?lenderId=${siteConfig.servicerLenderId || 'ABSL'}&loanGuiId=${loanId.slice(1, -1)}`,
				'_blank'
			)
	}

	const handleOpenLOConnect = () => {
		let url = 'https://encompassloconnect.com/pipeline'

		if (loanId) {
			const formId = '3ea1f6a1-afc2-4b01-b299-e69e9ba6262e'
			url += `/${loanId.slice(1, -1)}/standard-forms/${formId}`
		}
		window.open(url, '_blank', 'noopener,noreferrer')
	}

	const closeInviteModal = () => {
		setModalWizard({ data: {}, title: '', visible: false })
		ventiState.set(theme.storageKeys.inviteCoBorrower, false)
	}

	const handleAddCoBorrower = () => {
		if (loanData.LoanLocked !== 'True') setAddCoBorrower(true)
		else
			alert('Cannot add a Co-Borrower to a Locked Loan File', {
				severity: 'error',
			})
	}

	useMemo(() => {
		if (
			invite?.emailAddress &&
			!invite.completedLoanApplication &&
			siteConfig.enabledServices.spanishFullApp &&
			siteConfig.enabledServices.fullApp
		) {
			setLanguageSelectorModalActions(inviteActions[invite.loanRole])
		}
	}, [invite])

	useEffect(() => {
		if (coBorrowerRoute) {
			ventiState.unset(theme.storageKeys.coBorrowerRoute)
			handleAddCoBorrower()
		}
	}, [coBorrowerRoute])

	useEffect(() => {
		if (!mountedRef.current) return null

		if (user?.isLoggedIn) {
			setActions(
				_getPageActions(
					getActionsByRole(user, siteConfig, {
						loanId,
						locked: loanData?.LoanLocked === 'True',
					})
				)
			)

			// // if the user only has one loan, set the loanId
			// if (!appSubmitted && user.loanIDs?.length === 1) {
			// 	ventiState.set(theme.storageKeys.loanId, user.loanIDs[0])
			// }
		} else {
			navigate(`/${navigationLinking.SignIn}`)
		}
	}, [user, loanId, siteConfig])

	useEffect(() => {
		if (loanId) {
			;(async () => {
				try {
					const docs =
						await TheBigPOSApi.getTaskDocumentsByLoan(loanId)
					ventiState.set(theme.storageKeys.loanDocs, docs.data)
				} catch (e) {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				}
			})()
		}
	}, [loanId])

	useEffect(() => {
		setActions(
			_getPageActions(
				getActionsByRole(user, siteConfig, {
					loanId,
					locked: loanData?.LoanLocked === 'True',
				})
			)
		) //.filter(_actionsFilter)))

		if (loanId && canUseTasks) {
			;(async () => {
				try {
					const tasks = await TheBigPOSApi.getLoanTasks(loanId)
					if (!mountedRef.current) return

					const activeTasks = tasks.data.filter(
						(task) => task.status === 'Outstanding'
					)

					const tasksCount = activeTasks.length
					setOutstandingTaskCount(tasksCount)

					// const hasDisclosureTasks = activeTasks.some(task => 3 === task.taskType)
					// setHasDisclosures(hasDisclosureTasks)
				} catch (e) {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				}
			})()
		}
	}, [loanId])

	useEffect(() => {
		if (inviteData?.EmailAddress) {
			;(async () => {
				try {
					await TheBigPOSApi.inviteUser(
						Object.assign({ loanID: loanId }, inviteData)
					)
					alert('The invitation was sent successfully')
					ventiState.set(theme.storageKeys.inviteCoBorrower, false)
					setInviteData({})
				} catch (e) {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				}
			})()
		}
	}, [inviteData, loanId])

	useEffect(() => {
		if (loanId && !isAdmin) {
			;(async () => {
				try {
					!loanIdModalVisible &&
						!tutorialVisible &&
						!showTutorialFromSubmit &&
						!newUser &&
						!addCoBorrower &&
						ventiState.set(
							theme.storageKeys.pleaseWaitMessage,
							'Fetching loan information'
						)
					setFetchingApplication(true)
					const getLoanDataResponse =
						await TheBigPOSApi.getLoanData(loanId)
					const data = getLoanDataResponse.data

					const subjectPropertyAddress = []
					subjectPropertyAddress.push(
						data.SubjectPropertyAddressStreet || 'TBD'
					)
					if (data.SubjectPropertyAddressUnitNumber)
						subjectPropertyAddress.push(
							`${data.SubjectPropertyAddressUnitType} ${data.SubjectPropertyAddressUnitNumber}`
						)
					if (data.SubjectPropertyAddressCity)
						subjectPropertyAddress.push(
							`${data.SubjectPropertyAddressCity}, ${data.SubjectPropertyAddressState} ${data.SubjectPropertyAddressZip}`
						)

					setLoanData({ ...data, subjectPropertyAddress })
					ventiState.set(
						theme.storageKeys.loanOfficer,
						data.loanOfficerSiteConfiguration
					)
					ventiState.set(theme.storageKeys.loanData, data)
					setLoanError(false)
				} catch (e) {
					if (impersonationRequest.status !== 'Active')
						stopImpersonation()
					else setLoanError(true)
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				} finally {
					setFetchingApplication(false)
					ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
				}
			})()
		}
	}, [loanId])

	useEffect(() => {
		if (
			user?.isLoggedIn &&
			[
				Roles.loanOfficer,
				Roles.branchManager,
				Roles.borrower,
			].includes(user.role)
		) {
			setFetchingApplications(true)
			;(async () => {
				try {
					if ([Roles.borrower].includes(user.role)) {
						const getLoansResponse = await TheBigPOSApi.getLoans()
						const loanApps = getLoansResponse.data

						setLoanApplications(loanApps.applications)
						if (
							loanApps.applications.length &&
							(isFirstLoad ||
								impersonationRequest.status === 'Active') &&
							!loanError
						) {
							ventiState.set(theme.storageKeys.isFirstLoad, true)
							if (loanApps.applications.length > 1) {
								if (!user.drafts.length) setLoanIdModalVisible(true)
							} else {
								ventiState.set(
									theme.storageKeys.loanId,
									loanApps.applications[0].loanID
								)
							}
						}
					}
					setFetchingApplications(false)
				} catch (e) {
					setFetchingApplications(false)
					ventiState.set(
						theme.storageKeys.errorMessage,
						'There was a problem fetching your account details. Please try again later.'
					)
				}
			})()
		}

		if (loanData && 'False' === loanData.EConsented) {
			;(async () => {
				let questions
				const getFormBySiteConfigurationSlugResponse =
					await TheBigPOSApi.getFormBySiteConfigurationSlug({
						siteConfigurationId: siteConfig.id,
						formType: routeTypes.indexOf('EConsent'),
					})
				const form = getFormBySiteConfigurationSlugResponse.data

				if (
					!!form.id &&
					form.id !== '00000000-0000-0000-0000-000000000000'
				)
					questions = form.formJSON
				else
					questions = JSON.parse(
						JSON.stringify(require('../../workflows/e-consent.json'))
					)

				// remove credit auth from workflow if non-borrower
				if ('True' === loanData.CreditAuthed) {
					const qIndex = questions.findIndex(
						(q) => 'BorrowerCreditAuth' === q.fieldId
					)
					if (-1 !== qIndex) {
						questions[qIndex].qId = 'remove'
						if (questions[qIndex + 1]?.qId)
							questions[qIndex - 1].target = questions[qIndex + 1].qId
						else questions[qIndex - 1].target = ''
					}
				}

				// remove credit auth from workflow if non-borrower
				if ('Y' === loanData.BorrowerTCPAOptIn) {
					const qIndex = questions.findIndex(
						(q) => 'BorrowerTCPAOptIn' === q.fieldId
					)
					if (-1 !== qIndex) {
						questions[qIndex].qId = 'remove'
						if (questions[qIndex + 1]?.qId)
							questions[qIndex - 1].target = questions[qIndex + 1].qId
						else questions[qIndex - 1].target = ''
					}
				}

				setConsentQuestions(
					questions.filter((q) => q.qId !== 'remove')
				)
			})()
		}
	}, [user, loanData])

	useEffect(() => {
		if (user.isLoggedIn && user.drafts?.length > 0 && !appPosting) {
			setDraftModalConfirmation((current) => ({
				...current,
				open: true,
				text: 'You have an application in progress. Would you like to continue where you left off?',
			}))
		} else if (appPosting && user.Role === 'Borrower' && !newUser) {
			setTutorialVisible(true)
		}
	}, [user, appPosting])

	const handleAppLink = async (action) => {
		if (action?.id === 'support') {
			mixpanel.trackEvent(eventTypes.NEED_HELP)
		} else if (action?.id === 'encompassWeb') {
			mixpanel.trackEvent(eventTypes.ENCOMPASS_WEB)
		}

		if (action.route)
			navigate(`/${action.route}`, { state: action } || {})
		else if (action.action) {
			switch (action.action) {
				case 'invite':
					handleAddCoBorrower()
					break
				case 'duplicate':
					await handleDuplicateLoan()
					break
				case 'disclosures':
					await handleDisclosures()
					break
				case 'payment':
					handleOpenPayment()
					break
				case 'loConnect':
					handleOpenLOConnect()
					break
				case 'loanDetails':
					await handleOpenLoanDetails()
					break
				default:
					return
			}
		} else if (action.href) {
			window.open(action.href, '_blank', 'noopener,noreferrer')
		}
	}

	const handleOpenLoanDetails = async () => {
		await ventiState.set(
			theme.storageKeys.editingLoanApplicationId,
			loanId
		)
		const navigatePath = generatePath(
			`/${navigationLinking.LoanApplication}`,
			{ loanId }
		)
		navigate(navigatePath)
	}

	const handleSendEConsent = async (data) => {
		const questions = consentQuestions
		try {
			setConsentQuestions([])
			ventiState.set(
				theme.storageKeys.pleaseWaitMessage,
				'Please wait while we verify your account'
			)
			await TheBigPOSApi.updateLoan(loanId, {
				LoanID: loanId,
				SiteConfigurationID: siteConfig.id,
				...data,
			})

			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.successObject, {
				message: `Authorization was successful. Access to your account has been enabled`,
			})
			setTutorialVisible(true)
		} catch (err) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(
				theme.storageKeys.infoMessage,
				'It looks like your loan is currently being worked on. Please check back again later.'
			)
			//setConsentQuestions(questions)
		}
	}

	const stopImpersonation = () => {
		ventiState.set(theme.storageKeys.loanId, null)
		ventiState.set(theme.storageKeys.loanData, null)
		ventiState.set(theme.storageKeys.loanDocs, null)

		setFetchingApplication(false)
		setLoanApplications([])
		setLoanData({})
		setActions(_getPageActions(getActionsByRole(user, siteConfig)))
	}

	const closeTutorial = () => {
		setTutorialVisible(false)
		ventiState.set(theme.storageKeys.appSubmitted, false)
	}

	let slideLogoUri

	// eslint-disable-next-line default-case
	switch (true) {
		case user.role === Roles.loanOfficer:
			slideLogoUri = userAvatar
			break
		case [Roles.borrower, Roles.superAdmin].includes(user.role):
			slideLogoUri =
				loanOfficer.profilePhotoUrl ||
				siteConfig.profilePhotoUrl ||
				siteConfig.portalLogoUrl
	}

	const handleError = () => {
		setIsLogoUrlValid(false)
	}

	const handleOpenDraft = async () => {
		try {
			setDraftModalConfirmation((current) => ({
				...current,
				open: false,
			}))
			const draft = await TheBigPOSApi.getLoanDraft(user.drafts[0].id)
			applyUser({ ...user, drafts: [] })
			ventiState.set(theme.storageKeys.loanDraft, draft.data)
			navigate(`/${navigationLinking[draft.routeName || 'Apply']}`)
		} catch (e) {
			ventiState.set(theme.storageKeys.errorObject, {
				message:
					'There was a problem retrieving the saved application.',
			})
		}
	}

	const handleRemoveDraft = async () => {
		try {
			setDraftModalConfirmation((current) => ({
				...current,
				open: false,
			}))
			await TheBigPOSApi.deleteLoanDraft(user.drafts[0].id)
			applyUser({ ...user, drafts: [] })
		} catch (e) {
			ventiState.set(theme.storageKeys.errorObject, {
				message:
					'There was a problem removing the saved application.',
			})
		}
	}

	const showTutorialFromSubmit = appSubmitted && !newUser

	return (
		<>
			<Page page="app-portal" title="Portal" isFullWidth={true}>
				<div className="-z-1 xl:w-full xl:flex xl:items-baseline xl:flex-row xl:justify-center">
					<div className="w-full m-0">
						<div className="w-full m-0 h-full pb-7 flex flex-col md:flex-row">
							{width > 1023 &&
							isLogoUrlValid &&
							[Roles.borrower].includes(user.role) ? (
								<img
									className="max-w-[200px] self-start lg:mt-6 lg:ml-6"
									src={slideLogoUri}
									onError={handleError}
									alt="logo"
								/>
							) : null}

							{/* Borrower */}
							{[Roles.borrower].includes(user.role) && (
								<>
									<div className="pt-5 md:pb-8 pl-6 pr-2 ml-2 lg:px-2">
										{(siteConfig.entityType === 3 ||
											loanOfficer.name) && (
											<p className="text-lg font-rubik font-bold mb-1.5 pb-2.5 dark:text-white">
												Your {siteConfig.userTitle}
											</p>
										)}
										<p className="text-2xl font-rubik font-bold dark:text-white">
											{loanOfficer.name || siteConfig.name}
										</p>
										{!!(loanOfficer.nmlsid || siteConfig.nmlsid) && (
											<p className="flex first-letter:font-rubik font-bold mb-5 text-slate-500 dark:text-white">
												NMLS #:{' '}
												{loanOfficer.nmlsid || siteConfig.nmlsid}
											</p>
										)}
										<p className="flex font-rubik text-slate-500">
											mobile:&nbsp;
											<a
												target="_blank"
												href={`tel:${loanOfficer.phone || siteConfig.phone}`}
												rel="noopener noreferrer"
												className="hover:underline"
											>
												{loanOfficer.phone || siteConfig.phone}
											</a>
										</p>
										{!!siteConfig.tollFree && (
											<p className="flex font-rubik text-slate-500">
												office:&nbsp;
												<a
													target="_blank"
													href={`tel:${loanOfficer.tollFree || siteConfig.tollFree}`}
													rel="noopener noreferrer"
													className="hover:underline"
												>
													{loanOfficer.tollFree ||
														siteConfig.tollFree}
												</a>
											</p>
										)}
										{!!siteConfig.fax && (
											<p className="flex font-rubik text-slate-500">
												fax: {siteConfig.fax}
											</p>
										)}
										{!!(loanOfficer.email || siteConfig.email) && (
											<p className="flex font-rubik font-bold md:mb-5 text-slate-500">
												email:&nbsp;
												<a
													target="_blank"
													href={`mailto:${loanOfficer.email || siteConfig.email}`}
													rel="noopener noreferrer"
													className="hover:underline"
												>
													{loanOfficer.email || siteConfig.email}
												</a>
											</p>
										)}
									</div>
								</>
							)}

							{loanId &&
								[
									Roles.loanOfficer,
									Roles.branchManager,
									Roles.settlementAgent,
									Roles.realtor,
									Roles.loanOfficerAssistant,
									Roles.loanProcessor,
								].includes(user.role) && (
									<div className="pt-5 pb-8 pl-6 pr-5 break-words">
										{!loanData?.BorrowerEmail ? (
											<p className="self-baseline font-rubik text-lg mt-1 text-slate-600">
												Fetching loan data...
											</p>
										) : (
											<>
												<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex">
													<div className={`pr-1 text-slate-500`}>
														Loan Number:
													</div>
													<div className={`font-bold`}>
														{loanData?.LoanNumber}
													</div>
												</p>
												<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex">
													<div className={`pr-1 text-slate-500`}>
														Primary Borrower:
													</div>
													<div className={`font-bold`}>
														{loanData?.BorrowerFirstName}{' '}
														{loanData?.BorrowerLastName}
													</div>
												</p>
												<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex">
													<div className={`pr-1 text-slate-500`}>
														Contact Phone:
													</div>
													<div className={`font-bold`}>
														{loanData?.BorrowerContactPhone}
													</div>
												</p>
												<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex">
													<div className={`pr-1 text-slate-500`}>
														Email:
													</div>
													<div className={`font-bold`}>
														{loanData?.BorrowerEmail}
													</div>
												</p>
												<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex">
													<div className={`pr-1 text-slate-500`}>
														Address:
													</div>
													<div className={`font-bold`}>
														{loanData?.subjectPropertyAddress?.join(
															', '
														)}
													</div>
												</p>
											</>
										)}

										<button onClick={stopImpersonation}>
											<p className="font-bold text-sm text-white mt-1 bg-red-500 w-30 p-1 text-center rounded-sm md:mt-2.5 md:w-36">
												{[
													Roles.loanOfficer,
													Roles.branchManager,
												].includes(user.role)
													? 'Stop Impersonating'
													: 'Close File'}
											</p>
										</button>
									</div>
								)}

							{!loanId &&
								[Roles.loanOfficer, Roles.branchManager].includes(
									user.role
								) && (
									<div className="border-b-2 pt-8 pb-8 pl-0 w-full">
										<p className="text-2xl font-rubik font-bold pl-8 dark:text-white">
											Loan Officer Dashboard
										</p>
									</div>
								)}

							{!loanId && [Roles.realtor].includes(user.role) && (
								<div className="border-b-2 pt-8 pb-8 pl-0 w-full">
									<p className="text-2xl font-rubik font-bold pl-8 dark:text-white">
										Agent Dashboard
									</p>
								</div>
							)}

							{/* ADMIN */}
							{isAdmin && (
								<div className="border-b-2 pt-8 pb-8 pl-0 w-full">
									<p className="text-2xl font-rubik font-bold pl-8 dark:text-white">
										{user.role === Roles.superAdmin && 'Super '}Admin
										Dashboard
									</p>
								</div>
							)}

							{/*{!!loanId && [Roles.borrower, Roles.superAdmin].includes(user.role) &&*/}
							{/*	<div className="md:pt-4 pb-8 md:mt-2.5 pl-6 md:px-2">*/}
							{/*		<div className="flex flex-col justify-around">*/}
							{/*			<div className="mt-10">*/}
							{/*				{fetchingApplication*/}
							{/*					? <p className="text-slate-600 font-rubik">Fetching loan information...</p>*/}
							{/*					: <>*/}
							{/*						<p className="font-rubik dark:text-white">Loan Details</p>*/}
							{/*						<p className="font-bold font-rubik mb-5 text-slate-500">${loanData.LoanAmount} ({loanData.LoanPurpose})</p>*/}
							{/*						<p className="text-slate-500 font-rubik">{loanData.SubjectPropertyAddressCity}, {loanData.SubjectPropertyAddressState} {loanData.SubjectPropertyAddressZip}</p>*/}
							{/*					</>*/}
							{/*				}*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*}*/}

							{[Roles.borrower].includes(user.role) &&
								loanData?.LoanAmount && (
									<div className="pt-5 ml-40 justify-self-end hidden md:block">
										<p className="text-lg font-rubik font-bold mb-1.5 pb-2.5 dark:text-white">
											Your Loan Summary
										</p>
										<div>
											<span className="text-slate-400">
												Borrower Name:
											</span>{' '}
											{loanData.BorrowerFirstName}{' '}
											{loanData.BorrowerLastName}
										</div>
										{!!loanData.LoanPurpose && (
											<div>
												<span className="text-slate-400">
													Purpose:
												</span>{' '}
												{loanData.LoanPurpose}
											</div>
										)}
										{loanData.LoanPurpose === 'Purchase' && (
											<div>
												<span className="text-slate-400">
													Purchase Price:
												</span>{' '}
												${loanData.SubjectPropertySalePrice}
											</div>
										)}
										{loanData.LoanPurpose === 'Purchase' && (
											<div>
												<span className="text-slate-400">
													Down Payment:
												</span>{' '}
												${loanData.DownPaymentAmount || 0}
											</div>
										)}
										<div>
											<span className="text-slate-400">
												Loan Amount:
											</span>{' '}
											${loanData.LoanAmount}
										</div>
										<div>
											<span className="text-slate-400">Term:</span>{' '}
											{loanData.LoanTerm
												? `${parseInt(loanData.LoanTerm, 10) / 12} Years`
												: 'Pending'}
										</div>
										<div>
											<span className="text-slate-400">Type:</span>{' '}
											{loanData.CurrentLoanType}
										</div>
										<div>
											<span className="text-slate-400">Status:</span>{' '}
											{loanData.LoanStatus}
										</div>
										{loanApplications.length > 1 && (
											<Typography
												onClick={() => setLoanIdModalVisible(true)}
												variant="link"
												className="font-bold text-sm"
											>
												work with a different application
											</Typography>
										)}
									</div>
								)}
						</div>
					</div>
				</div>

				<div className="border-b-2">
					<div className="flex items-center flex-col w-full">
						<div
							className="flex flex-row flex-wrap justify-center max-w-screen-xl mx-auto mb-10"
							style={{ minHeight: '300px' }}
						>
							{!fetchingApplication ? (
								actions.map((action, index) => {
									if (siteConfig.enabledServices[action.id] === false)
										return false
									return (
										<CardItem
											item={action}
											key={index}
											index={index}
											onClick={() => handleAppLink(action)}
											source={'Portal'}
											notificationCount={
												action.id === 'tasks'
													? outstandingTaskCount
													: 0
											}
										/>
									)
								})
							) : (
								<Loading size="small" />
							)}
						</div>
						<div />
					</div>
				</div>
				<Footer
					siteConfig={siteConfig}
					socialLinks={socialLinks}
					legalLinks={legalLinks}
				/>
			</Page>

			<ModalWizard
				modal={modalWizard.visible}
				modalData={modalWizard.data}
				setModal={closeInviteModal}
				questions={inviteCoBorrowerQuestions}
				save={sendInvitation}
				finalButtonText={`Send Invitation`}
				modalTitle={modalWizard.title}
			/>

			<ModalYesNo
				modalConfirmation={draftModalConfirmation}
				modalConfirm={handleOpenDraft}
				modalDismiss={handleRemoveDraft}
				setModalConfirmation={setDraftModalConfirmation}
			/>

			<ModalLoanAppSelector
				visible={
					loanIdModalVisible &&
					loanApplications.length > 1 &&
					Roles.borrower === user.role &&
					!consentQuestions.length &&
					!loanError
				}
				setVisible={setLoanIdModalVisible}
				siteConfig={siteConfig}
				loanApplications={loanApplications}
				setActiveLoanApp={(selectedApp) =>
					ventiState.set(theme.storageKeys.loanId, selectedApp.loanID)
				}
			/>

			<ModalNewUser visible={newUser && user.isLoggedIn} />

			<ModalAddCoBorrower
				blacklist={loanData?.allBorrowers?.map(
					(borrower) => borrower.email
				)}
				role={user?.role}
				visible={
					addCoBorrower && !appSubmitted && !appFailed && !newUser
				}
				setVisible={setAddCoBorrower}
				siteConfig={siteConfig}
			/>

			<ModalTutorial
				visible={tutorialVisible || showTutorialFromSubmit}
				setVisible={closeTutorial}
				siteConfig={siteConfig}
				width={width}
				user={user}
			/>

			<ModalPleaseWait
				visible={
					appPosting &&
					!tutorialVisible &&
					!showTutorialFromSubmit &&
					!appFailed &&
					!inviteCoBorrower &&
					!newUser
				}
				logo={siteConfig.logoUrl}
				message={`Please be patient while we prepare your application. Do not close this window, refresh the page or hit the back button on your browser.`}
			/>

			<ModalPleaseWait
				visible={
					!appSubmitted &&
					!appFailed &&
					fetchingApplications &&
					!loanId &&
					!languageSelectorModalActions.length &&
					Roles.borrower === user.role
				}
				logo={siteConfig.logoUrl}
				message={`Retrieving account information`}
			/>

			<ModalPleaseWait
				visible={fetchingDisclosureInfo}
				logo={siteConfig.logoUrl}
				message={`Fetching Disclosure Package Information`}
			/>

			<ModalAppError
				visible={!appSubmitted && failoverModalVisible}
				siteConfig={siteConfig}
			/>

			{consentQuestions.length > 0 && (
				<ModalWizard
					modalTitle="Welcome to the Borrower Portal!"
					finalButtonText="Finish"
					questions={consentQuestions}
					siteConfig={siteConfig}
					modal={Roles.borrower === user.role}
					setModal={() => false}
					save={handleSendEConsent}
				/>
			)}

			{languageSelectorModalActions.length > 1 && (
				<ModalWorkflowSelector
					actions={languageSelectorModalActions}
					setActions={setLanguageSelectorModalActions}
				/>
			)}
		</>
	)
}
