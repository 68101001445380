import React, { useEffect, useState } from 'react'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import {
	Task,
	TaskRequest,
	TaskSearchCriteria,
} from '@matech/thebigpos-sdk'
import DataTable, { ActionItem } from '../../../components/DataTable'
import queryKeys from '../../../services/queryKeys'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import useSearch from '../../../hooks/useSearch'
import usePagination from '../../../hooks/usePagination'
import { getTheme } from '../../../config'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { useAlert } from '../../../hooks'
import { FixMeLater } from '../../../types'
import TrueFalseIcon from '../../../components/icons/TrueFalseIcon'
import ModalTaskForm from '../../../components/modals/ModalTaskForm'
import { Values } from '../../../forms/TaskForm'

const theme = getTheme()

const GlobalTasks = () => {
	const { alert } = useAlert()
	const queryClient = useQueryClient()

	const { searchText, handleSearchChange, handleClearSearchClick } =
		useSearch({})

	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [selectedTask, setSelectedTask] = useState<Task | null>(null)
	const [taskModalOpen, setTaskModalOpen] = useState(false)

	const { defaultSortColumn, defaultSortDirection } = theme.pagination
	const searchCriteria: TaskSearchCriteria = {
		searchText,
		isGlobal: true,
	}

	const {
		pageNumber,
		pageSize,
		sortBy,
		sortDirection,
		handleSort,
		handleRowsPerPageChange,
		handlePageChange,
	} = usePagination<Task>({
		defaultSortBy: defaultSortColumn,
		defaultSortDirection: defaultSortDirection as FixMeLater,
	})

	const {
		isFetching,
		isRefetching,
		isError,
		data: result,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.tasks,
			searchCriteria,
			pageNumber,
			pageSize,
			sortBy,
			sortDirection,
		],
		queryFn: () =>
			TheBigPOSApi.searchTasks(searchCriteria, {
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			}),
	})

	useEffect(() => {
		if (!isError) return

		alert('There was a problem loading tasks', {
			severity: 'error',
		})
	}, [isError, alert])

	const columns = [
		{
			name: 'Name',
			selector: (row: Task) => row.name,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row: Task) => row.type,
		},
		{
			name: 'Auto-Complete',
			selector: (row: Task) => row.willAutocompleteAfterResponse,
			cell: (row: Task) => (
				<TrueFalseIcon value={row.willAutocompleteAfterResponse} />
			),
			center: true,
		},
		{
			name: 'Used in Rule',
			selector: (row: Task) => row.usedInBusinessRule,
			cell: (row: Task) => (
				<TrueFalseIcon value={row.usedInBusinessRule} />
			),
			center: true,
		},
		{
			name: 'Created At',
			sortField: 'createdAt',
			selector: (row: Task) => row.createdAt,
			cell: (row: Task) => formatDate(row.createdAt, false),
			sortable: true,
		},
	]

	const updateRuleMutation = useMutation({
		mutationFn: async (data: TaskRequest) => {
			await TheBigPOSApi.replaceTask(selectedTask?.id || '', data)
		},
	})

	const deleteMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.deleteTask(id),
		onSuccess: async () => {
			alert('The task was successfully deleted', {
				severity: 'success',
			})
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.tasks],
			})
		},
	})

	const invalidateTasks = async () => {
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.tasks],
		})
	}

	const handleDeleteConfirm = async () => {
		if (selectedTask) {
			try {
				await deleteMutation.mutateAsync(selectedTask.id)
				await invalidateTasks()
			} catch (e) {
				alert(getErrorMessage(e), {
					severity: 'error',
				})
			} finally {
				setRemoveModalVisible(false)
			}
		}
	}

	const handleDeleteClick = (row: Task) => {
		setSelectedTask(row)
		setRemoveModalVisible(true)
	}

	const handleTaskSubmit = async (data: Values) => {
		try {
			const {
				name,
				description,
				type,
				isGlobal,
				daysDueFromApplication,
				losTarget,
				targetUserRole,
				willAutocompleteAfterResponse,
				hasAutoPropagationOnAdd,
			} = data

			const request: TaskRequest = {
				name,
				description,
				type,
				isGlobal,
				daysDueFromApplication,
				losTarget,
				targetUserRole,
				willAutocompleteAfterResponse,
				hasAutoPropagationOnAdd,
			}
			await updateRuleMutation.mutateAsync(request)
			await invalidateTasks()
			setTaskModalOpen(false)
			setSelectedTask(null)
			alert(`Task successfully updated`)
		} catch (e) {
			setTaskModalOpen(false)
			setSelectedTask(null)
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleTaskEditClick = (row: Task) => {
		setSelectedTask(row)
		setTaskModalOpen(true)
	}

	const handleTaskModalClose = () => {
		setTaskModalOpen(false)
		setSelectedTask(null)
	}

	const actionItems: ActionItem<Task>[] = [
		{
			name: 'Edit',
			onClick: (e, row) => handleTaskEditClick(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => handleDeleteClick(row),
		},
	]

	return (
		<>
			<ModalTaskForm
				open={taskModalOpen}
				task={selectedTask || undefined}
				onSubmit={handleTaskSubmit}
				onClose={handleTaskModalClose}
				isGlobalDisabled
			/>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={handleDeleteConfirm}
				row={selectedTask}
				loading={deleteMutation.isPending}
			/>
			<DataTable
				data={result?.data?.rows || []}
				columns={columns}
				title="Global Tasks"
				progressPending={isFetching || isRefetching}
				onRefreshClick={refetch}
				fixedHeader
				actionItems={actionItems}
				onSearchChange={handleSearchChange}
				onSort={handleSort}
				searchText={searchText}
				onClearSearchClick={handleClearSearchClick}
				defaultSortFieldId={sortBy}
				defaultSortAsc={sortDirection === 'asc'}
				onChangePage={handlePageChange}
				onChangeRowsPerPage={handleRowsPerPageChange}
				pagination
				paginationTotalRows={result?.data?.count}
				paginationServer
			/>
		</>
	)
}

export default GlobalTasks
