import React from 'react'
import RowExpandedProperty from '../../../../components/RowExpandedProperty'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import { Button } from '../../../../components/Button'
import { generatePath, useNavigate } from 'react-router-dom'
import { navigationLinking } from '../../../../services/navigation'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import useUser from '../../../../hooks/useUser'
import { Tooltip } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import queryKeys from '../../../../services/queryKeys'
import { useAlert } from '../../../../hooks'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { getLoanDocumentUrl } from '../../../../services/client'
import { Check } from '@mui/icons-material'

const TaskRowExpanded = ({ data: loanTask }) => {
	const {
		status,
		value,
		loanID,
		documents,
		submittedBy,
		completedBy,
		task,
	} = loanTask
	const { description, type } = task
	const navigate = useNavigate()
	const { isLoanAdmin } = useUser()
	const { alert } = useAlert()
	const queryClient = useQueryClient()

	const verificationFolderMap = {
		VerificationOfAssets: 'Electronic Verification of Assets',
		VerificationOfIncome: 'Electronic Verification of Income',
		VerificationOfEmployment: 'Electronic Verification of Employment',
		VerificationOfIncomeAndEmployment:
			'Electronic Verification of Income and Employment',
	}

	const retryFailedLoanDocumentMutation = useMutation({
		mutationFn: ({ documentId }) =>
			TheBigPOSApi.retryFailedLoanDocument(loanID, documentId),
	})

	const handleDownloadClick = async (document) => {
		const url = getLoanDocumentUrl(loanID, document.id)

		window.open(url, '_blank')
	}

	const handleVerificationTaskDocClick = () => {
		// @todo - add ability to view specific document. code in documents require base64 data & should just be able to pass the id or folder to the route
		const navigatePath = generatePath(
			`/${navigationLinking.Documents}`,
			{ loanId: loanTask.loanID }
		)
		navigate(navigatePath, {
			state: { folder: verificationFolderMap[loanTask.type] },
		})
	}

	const handleRetryUpload = async (documentId) => {
		try {
			await retryFailedLoanDocumentMutation.mutateAsync({
				documentId,
			})
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanTasks, loanTask.loanID],
			})
			alert('Document was successfully sent to the LOS', {
				severity: 'success',
			})
		} catch (e) {
			alert(
				'Error sending document. Please make sure the loan is unlocked in your LOS.',
				{
					severity: 'error',
				}
			)
		}
	}

	return (
		<div className="p-5 dark:text-white">
			{description && (
				<RowExpandedProperty
					label="Description"
					value={description}
				/>
			)}
			{type === 'Field' && !value && (
				<RowExpandedProperty label="Response" value="Not answered" />
			)}

			{value && (
				<>
					<RowExpandedProperty label="Response" value={value} />
					{submittedBy && (
						<p className="text-gray-500 text-sm">
							Answered by {submittedBy.firstName}{' '}
							{submittedBy.lastName}
						</p>
					)}
				</>
			)}

			{type === 'Document' && documents.length === 0 && (
				<div>No documents uploaded</div>
			)}
			{documents.map((document) => {
				return (
					<div
						key={document.id}
						className="flex flex-row items-center"
					>
						{document.losStatus === 'FailedPermanently' ? (
							<Tooltip title="This document failed to upload to your LOS and cannot be recovered.">
								<div className="ml-2 mr-2 cursor-pointer">
									<ErrorIcon color="error" />
								</div>
							</Tooltip>
						) : (
							<IconButton
								onClick={() => handleDownloadClick(document)}
							>
								<DownloadIcon />
							</IconButton>
						)}

						<div className="flex flex-row items-center">
							{document.name || 'Untitled'}
							{(document.losStatus === 'Retrying' ||
								document.losStatus === 'Failed') &&
								isLoanAdmin && (
									<>
										<Tooltip
											title={
												document.losStatus === 'Retrying'
													? 'The document failed to upload to your LOS and will retry every 30 minutes for 24 hours. Please make sure the loan is not locked to avoid delays.'
													: 'The document failed to upload to your LOS. Please contact support.'
											}
										>
											<div className="ml-2 cursor-pointer">
												<WarningIcon color="warning" />
											</div>
										</Tooltip>
										<div className="ml-2">
											<Button
												text="Retry Upload to LOS"
												onClick={() => handleRetryUpload(document.id)}
												loading={
													retryFailedLoanDocumentMutation.isPending
												}
												disabled={
													retryFailedLoanDocumentMutation.isPending
												}
												variant="outlined"
											/>
										</div>
									</>
								)}
						</div>
					</div>
				)
			})}
			{type.includes('Verification') && (
				<>
					{status === 'Outstanding' && <div>Not complete</div>}
					{documents.length === 0 && status !== 'Outstanding' && (
						<Button
							text="View Document"
							variant="outlined"
							onClick={handleVerificationTaskDocClick}
						/>
					)}
				</>
			)}

			{isLoanAdmin && type !== 'Field' && (
				<RowExpandedProperty
					label="Bucket"
					value={
						task.losTarget
							? task.losTarget
							: task.type === 'Document'
								? 'Unassigned'
								: null
					}
					className="my-2"
				/>
			)}

			<RowExpandedProperty
				label="Auto Complete"
				value={task.willAutocompleteAfterResponse ? <Check /> : '-'}
				className="my-2"
			/>

			{/* {isLoanAdmin && (
				<RowExpandedProperty
					label="Auto Add"
					value={task?.hasAutoPropagationOnChange ? <Check /> : '-'}
					className="my-2"
				/>
			)} */}

			{status === 'Completed' && completedBy && (
				<p className="text-gray-500 text-sm">
					Completed by {completedBy.firstName} {completedBy.lastName}
				</p>
			)}
		</div>
	)
}

export default TaskRowExpanded
