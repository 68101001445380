// NPM packages
import React from 'react'
import * as yup from 'yup'

// Components
import { User } from '@matech/thebigpos-sdk'
import {
	TextField,
	Form,
	FormRow,
} from '../../../../components/formControls'
import { LoadingBtn as LoadingButton } from '../../../../components/Button'

// Services
import useForm from '../../../../hooks/useForm'
import { ErrorListItemProps } from '../../../../components/ErrorList'
import useUser from '../../../../hooks/useUser'

export type UserProfileFormData = {
	firstName?: string
	lastName?: string
	title?: string
}

export type UserProfileFormProps = {
	user: User
	onSubmit: (data: UserProfileFormData) => Promise<void>
	apiErrors?: ErrorListItemProps[]
}

const UserProfileForm = ({
	user,
	onSubmit,
	apiErrors,
}: UserProfileFormProps) => {
	const { isAdmin } = useUser()

	const schema = yup
		.object()
		.shape({
			firstName: yup.string().required(),
			lastName: yup.string().required(),
			title: yup.string(),
		})
		.required()

	const {
		control,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm({
		schema,
		defaultValues: {
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
			title: user?.title || '',
		},
	})

	const handleFormSubmit = async (data: UserProfileFormData) => {
		await onSubmit(data)
	}
	return (
		<Form
			onSubmit={handleSubmit(handleFormSubmit)}
			errors={apiErrors}
		>
			<FormRow>
				<TextField
					name="firstName"
					label="First Name"
					fullWidth
					control={control}
					errors={errors}
					required
				/>
			</FormRow>
			<FormRow>
				<TextField
					name="lastName"
					label="Last Name"
					fullWidth
					control={control}
					errors={errors}
					required
				/>
			</FormRow>
			<FormRow>
				<TextField
					name="title"
					label="Title"
					fullWidth
					control={control}
					errors={errors}
					disabled={!isAdmin}
				/>
			</FormRow>
			<LoadingButton
				type="submit"
				text="Save"
				loading={isSubmitting}
				disabled={isSubmitting}
			/>
		</Form>
	)
}

export default UserProfileForm
