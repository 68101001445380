import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import Page from '../../../components/Page'
import { useAlert, useWindowSize } from '../../../hooks'
import { getTheme } from '../../../config'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { useMutation, useQuery } from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import TaskStatusBadge from './components/TaskStatusBadge'
import DataTable from '../../../components/DataTable'
import TaskRowExpanded from './components/TaskRowExpanded'
import TaskTypeLabel from './components/TaskTypeLabel'
import RefreshButton from '../../../components/RefreshButton'
import AddTaskButtonGroup from './components/AddTaskButtonGroup'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import useUser from '../../../hooks/useUser'
import { cloneDeep } from 'lodash'
import ModalTaskForm from '../../../components/modals/ModalTaskForm'
import { useNavigate, useParams } from 'react-router-dom'
import { navigationLinking } from '../../../services/navigation'
import LoanTaskActionButton from './components/LoanTaskActionButton'
import LoanTaskSummary from './components/LoanTaskSummary'
import TaskSearchFilters from './components/TaskSearchFilters'
import { formatDate } from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import WarningIcon from '@mui/icons-material/Warning'
import useTask from './hooks/useTask'
import clsx from 'clsx'
import withAuth from '../../../components/withAuth'
import LoanTaskDiffAlert from './components/LoanTaskDiffAlert'
import LoanTaskReminderAlert from './components/LoanTaskReminderAlert'
import TaskRowName from './components/TaskRowName'
import AlertBox from '../../../components/AlertBox'
import { useAppContext } from '../../../components/AppContext'
import { disclosuresSSOSignIn } from '../../../services/utils'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import useUserAgent from '../../../hooks/useUserAgent'

const theme = getTheme()

const Tasks = () => {
	const { dataTable: breakpoint } = theme.breakpoints

	const { alert } = useAlert()
	const mixpanel = useMixpanel()
	const { isBorrower, isRealtor, user, isLoanAdmin } = useUser()
	const navigate = useNavigate()
	const { loanId } = useParams()
	const { isOverdue, getDueDate } = useTask()
	const [width] = useWindowSize()
	const { state } = useAppContext()
	const { siteConfig, authToken } = state
	const { mobileOs } = useUserAgent()

	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [selectedLoanTask, setSelectedLoanTask] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [filteredTasks, setFilteredTasks] = useState([])
	const [selectedUserId, setSelectedUserId] = useState(null)
	const [selectedStatus, setSelectedStatus] = useState(null)
	const [taskModalOpen, setTaskModalOpen] = useState(false)

	const isTaskActionEnabled = useCallback(
		(row) => {
			return (
				!isRealtor &&
				((row.task.type === 'Document' &&
					row.status !== 'Completed') ||
					row.status === 'Outstanding' ||
					row.status === 'Rejected')
			)
		},
		[user]
	)

	const handleLoanTaskButtonClick = (loanTask) => {
		const { task } = loanTask
		if (['EDisclosure', 'EConsent'].includes(task.type)) {
			if (siteConfig.enabledServices.disclosures) {
				disclosuresSSOSignIn(siteConfig, authToken)
			} else {
				navigate(`/${navigationLinking.Disclosures}`)
			}
		} else {
			setSelectedLoanTask(loanTask)
		}
	}

	const { data: loan, isFetching: isFetchingLoan } = useQuery({
		queryKey: [queryKeys.loan, loanId],
		queryFn: () => TheBigPOSApi.getLoanData(loanId),
		refetchOnWindowFocus: mobileOs !== 'android',
	})

	const isMobile = width < breakpoint

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.task.name,
			cell: (row) => {
				const isMobileAndActionable = isTaskActionEnabled && isMobile
				return (
					<div className={isMobileAndActionable ? 'py-2' : undefined}>
						<TaskRowName task={row.task} />
						{isMobileAndActionable && (
							<div className="mt-1">
								<LoanTaskActionButton
									loanTask={row}
									onClick={() => handleLoanTaskButtonClick(row)}
									onChange={refetch}
								/>
							</div>
						)}
					</div>
				)
			},
			sortable: true,
			width: isMobile ? '70%' : '30%',
		},
		{
			name: 'Type',
			selector: (row) => row.task.type,
			cell: (row) => <TaskTypeLabel type={row.task.type} />,
			hide: breakpoint,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			cell: (row) => (
				<TaskStatusBadge
					status={row.status}
					statusChangedDate={
						row.status === 'Outstanding'
							? row.createdAt
							: row.statusChangedDate
					}
				/>
			),
			sortable: true,
			hide: breakpoint,
		},
		{
			id: 'dueDate',
			name: 'Due Date',
			selector: (row) => {
				if (!loan?.data) return null
				return getDueDate(
					loan.data.CreatedAt,
					row.task.daysDueFromApplication
				).toISOString()
			},
			cell: (row) => {
				if (!loan?.data) return null
				const dueDate = getDueDate(
					row.createdAt,
					row.task.daysDueFromApplication
				)

				const overdue = isOverdue(
					row.createdAt,
					row.task.daysDueFromApplication,
					row.status
				)

				const formattedDueDate = formatDate(dueDate, false)
				return (
					<div
						className={clsx(
							'flex flex-row items-center',
							overdue ? 'font-bold text-red-600' : ''
						)}
					>
						<div className="mr-2">{formattedDueDate}</div>
						{overdue && <WarningIcon color="error" />}
					</div>
				)
			},
			sortable: true,
			hide: breakpoint,
		},
		{
			id: 'createdAt',
			name: 'Created',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: breakpoint,
		},
		{
			name: '',
			cell: (row) => {
				if (isTaskActionEnabled(row)) {
					return (
						<LoanTaskActionButton
							loanTask={row}
							onClick={() => handleLoanTaskButtonClick(row)}
							onChange={refetch}
						/>
					)
				}
				return null
			},
			hide: breakpoint,
		},
	]

	if (!isBorrower) {
		columns.splice(2, 0, {
			name: 'Borrower',
			selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
			hide: breakpoint,
			sortable: true,
		})
	}

	const {
		isFetching,
		isRefetching,
		isError,
		data: loanTasks,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.loanTasks, loanId],
		queryFn: () => TheBigPOSApi.getLoanTasks(loanId),
		refetchOnWindowFocus: mobileOs !== 'android',
	})

	const updateTaskMutation = useMutation({
		mutationFn: async (data) =>
			TheBigPOSApi.replaceTask(selectedLoanTask?.task.id, data),
	})

	const updateLoanTaskMutation = useMutation({
		mutationFn: async (data) =>
			TheBigPOSApi.replaceLoanTask(loanId, data.id, data),
	})

	const removeTaskMutation = useMutation({
		mutationFn: async ({ id }) =>
			TheBigPOSApi.deleteLoanTask(loanId, id),
	})

	const { data: loanUsers, isFetching: isLoanUsersFetching } =
		useQuery({
			queryKey: [queryKeys.loanUsers, loanId],
			queryFn: () => TheBigPOSApi.getLoanUsers(loanId),
			refetchOnWindowFocus: mobileOs !== 'android',
		})

	useEffect(() => {
		if (isError) {
			alert('There was a problem loading the tasks', {
				severity: 'error',
			})
		}
	}, [isError, alert])

	useEffect(() => {
		let filteredData = cloneDeep(loanTasks?.data || [])

		if (searchText) {
			filteredData = filteredData.filter((r) =>
				r.task.name.toLowerCase().includes(searchText.toLowerCase())
			)
		}

		if (selectedUserId) {
			filteredData = filteredData.filter(
				(r) => r.user.id === selectedUserId
			)
		}

		if (selectedStatus) {
			filteredData = filteredData.filter(
				(r) => r.status === selectedStatus
			)
		}

		setFilteredTasks(filteredData)
	}, [loanTasks, searchText, selectedUserId, selectedStatus])

	const handleEditClick = (row) => {
		setSelectedLoanTask(row)
		setTaskModalOpen(true)
	}

	const handleSaveTask = async (data) => {
		try {
			const result = await updateTaskMutation.mutateAsync(data)
			await refetch()
			alert('The task was successfully updated', {
				severity: 'success',
			})
			const { id, name } = result.data
			mixpanel.trackEvent(eventTypes.TASK_UPDATED, {
				id,
				name,
			})
		} catch (e) {
			alert('There was a problem updating the task', {
				severity: 'error',
			})
		} finally {
			setSelectedLoanTask(false)
			setTaskModalOpen(false)
		}
	}

	const handleDeleteClick = (row) => {
		setSelectedLoanTask(row)
		setRemoveModalVisible(true)
	}

	const handleDeleteConfirm = async () => {
		try {
			await removeTaskMutation.mutateAsync(selectedLoanTask)
			await refetch()
			alert('The task was successfully deleted', {
				severity: 'success',
			})
			const {
				id,
				task: { name },
			} = selectedLoanTask
			mixpanel.trackEvent(eventTypes.LOAN_TASK_REMOVED, {
				id,
				name,
			})
		} catch (e) {
			alert('There was a problem deleting the task', {
				severity: 'error',
			})
		} finally {
			handleTaskModalClose()
		}
	}

	const onNewTaskSave = async () => {
		await refetch()
	}

	const handleSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	const handleSearchClear = () => {
		setSearchText('')
	}

	const handleFilterChange = ({ status, borrowerId }) => {
		setSelectedStatus(status)
		setSelectedUserId(borrowerId)
	}

	const handleTaskModalClose = () => {
		setSelectedLoanTask(null)
		setRemoveModalVisible(false)
	}

	const updateTask = async (
		row,
		newStatus,
		eventForTracking = null
	) => {
		try {
			await updateLoanTaskMutation.mutateAsync({
				...row,
				status: newStatus,
			})

			alert('The task was successfully updated', {
				severity: 'success',
			})

			const { id, task } = row
			const { name } = task

			if (eventForTracking) {
				mixpanel.trackEvent(eventForTracking, {
					id,
					name,
				})
			}
			await refetch()
		} catch (e) {
			alert('There was a problem updating the task', {
				severity: 'error',
			})
		}
	}

	const handleCompleteTaskClick = async (row) => {
		await updateTask(row, 'Completed', eventTypes.LOAN_TASK_COMPLETED)
	}

	const handleRejectTaskClick = async (row) => {
		await updateTask(row, 'Rejected')
	}

	const borrowers = useMemo(() => {
		return loanUsers?.data
			? loanUsers.data.filter(
					(u) => u.role.includes('Borrower') && u.isUser
				)
			: []
	}, [loanUsers])

	const borrowerOptions = borrowers.map((borrower) => ({
		value: borrower.id,
		label: `${borrower.firstName} ${borrower.lastName}`,
	}))

	const isTaskEditable = (row) => {
		return (
			row.status === 'Outstanding' &&
			!row.task.isGlobal &&
			!row.task.usedInBusinessRule
		)
	}

	const actionItems = isLoanAdmin
		? [
				{
					name: 'Complete',
					onClick: (e, row) => handleCompleteTaskClick(row),
					hideIf: (row) => row.status !== 'Pending',
				},
				{
					name: 'Reject',
					onClick: (e, row) => handleRejectTaskClick(row),
					hideIf: (row) => row.status !== 'Pending',
				},
				{
					name: 'Edit',
					onClick: (e, row) => handleEditClick(row),
					hideIf: (row) => !isTaskEditable(row),
				},
				{
					name: 'Delete',
					onClick: (e, row) => handleDeleteClick(row),
				},
			]
		: []

	const outstandingTasksCount = useMemo(() => {
		return loanTasks?.data.filter(
			(task) => task.status === 'Outstanding'
		).length
	}, [loanTasks])

	const isPosLoan = useMemo(() => {
		return loan?.data.IsPOSLoan || false
	}, [loan])

	return (
		<Page page="tasks" title="Tasks" isFullWidth={true}>
			{selectedLoanTask && (
				<ModalTaskForm
					open={taskModalOpen}
					task={selectedLoanTask.task}
					onSubmit={handleSaveTask}
					onClose={handleTaskModalClose}
					borrowers={borrowers}
					loanId={loanId}
				/>
			)}

			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={handleDeleteConfirm}
				row={selectedLoanTask}
				loading={removeTaskMutation.isPending}
			/>

			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<div className="flex items-center justify-between mb-10">
					<div className="flex items-center text-2xl font-rubik font-bold mr-4 dark:text-white">
						<div>{isLoanAdmin ? 'Borrower' : 'My'} Tasks</div>
						<div className="ml-3">
							<RefreshButton
								onClick={refetch}
								disabled={isFetching || isRefetching}
							/>
						</div>
					</div>
					{isLoanAdmin && isPosLoan && (
						<AddTaskButtonGroup
							onSave={onNewTaskSave}
							loan={loan.data}
							loanUsers={borrowers}
							className="z-10"
							disabled={!loanUsers?.data}
						/>
					)}
				</div>
				{isLoanAdmin && (
					<>
						{isPosLoan && (
							<>
								<LoanTaskDiffAlert
									loanId={loanId}
									borrowers={borrowers}
									className="mb-3"
									onSubmit={refetch}
								/>
								{(siteConfig.enabledServices.emailNotifications ||
									siteConfig.enabledServices.textNotifications) && (
									<LoanTaskReminderAlert
										loanId={loanId}
										outstandingTasksCount={outstandingTasksCount}
										className="mb-3"
									/>
								)}
							</>
						)}
						{((!isFetchingLoan && !isPosLoan) ||
							(!isLoanUsersFetching && borrowers.length === 0)) && (
							<AlertBox severity="error" className="mb-3">
								No borrowers have been added to this loan yet. A
								borrower needs to accept their invite and complete
								their loan application before they are assigned.
							</AlertBox>
						)}
					</>
				)}

				<div>
					<p className="pb-8 text-lg text-slate-500">
						Please complete the tasks listed below by uploading the
						appropriate document. You may also be asked to complete
						missing information or sign a document. Completing all of
						your tasks in a timely manner will speed up the approval
						process. Acceptable file formats for document uploads are
						PDF, JPG and PNG.
					</p>

					{loanTasks?.data && (
						<LoanTaskSummary
							loanTasks={loanTasks.data}
							className="mb-8"
						/>
					)}

					<DataTable
						data={filteredTasks}
						columns={columns}
						defaultSortAsc={false}
						defaultSortFieldId="createdAt"
						pagination={true}
						progressPending={isFetching}
						keyField="id"
						expandableRows={true}
						refreshing={
							isRefetching || updateLoanTaskMutation.isPending
						}
						expandableRowsComponent={TaskRowExpanded}
						searchText={searchText}
						onSearchChange={handleSearchChange}
						onClearSearchClick={handleSearchClear}
						searchFilters={
							<TaskSearchFilters
								borrowers={borrowerOptions}
								onChange={handleFilterChange}
							/>
						}
						actionItems={!isBorrower ? actionItems : null}
					/>
				</div>
			</div>
		</Page>
	)
}

export default withAuth(Tasks)
