import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { TableColumn } from 'react-data-table-component'
import { DraftContent } from '@matech/thebigpos-sdk'
import queryKeys from '../../../../services/queryKeys'
import DataTable, {
	ActionItem,
} from '../../../../components/DataTable'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { useAlert } from '../../../../hooks'
import { formatDate } from '../../../../services/helper'
import { getTheme } from '../../../../config'
import { navigationLinking } from '../../../../services/navigation'
import useSearch from '../../../../hooks/useSearch'
import usePagination from '../../../../hooks/usePagination'
import { FixMeLater } from '../../../../types'
import { ModalRemoveRestoreRecordConfirm } from '../../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { ModalPleaseWait } from '../../../../components/modals/ModalPleaseWait'

const theme = getTheme()

const LoanDraftsTab = () => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const { searchText, handleSearchChange, handleClearSearchClick } =
		useSearch({})

	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState<DraftContent | null>(
		null
	)
	const { defaultSortColumn, defaultSortDirection } = theme.pagination
	const [fetching, setFetching] = useState(false)
	const searchCriteria = { searchText }

	const {
		pageNumber,
		pageSize,
		sortBy,
		sortDirection,
		handleSort,
		handleRowsPerPageChange,
		handlePageChange,
	} = usePagination<DraftContent>({
		defaultSortBy: defaultSortColumn,
		defaultSortDirection: defaultSortDirection as FixMeLater,
	})

	const {
		isFetching,
		isRefetching,
		isError,
		data: result,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.loanDrafts,
			searchCriteria,
			pageNumber,
			pageSize,
			sortBy,
			sortDirection,
		],
		queryFn: () =>
			TheBigPOSApi.searchLoanDrafts(searchCriteria, {
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			}),
	})

	useEffect(() => {
		if (!isError) return

		alert('There was a problem loading loan drafts', {
			severity: 'error',
		})
	}, [isError, alert])

	const columns: TableColumn<DraftContent>[] = [
		{
			name: 'First Name',
			selector: (row: DraftContent) => row.user.firstName,
		},
		{
			name: 'Last Name',
			selector: (row: DraftContent) => row.user.lastName,
		},
		{
			name: 'Email',
			selector: (row: DraftContent) => row.user.email,
		},
		{
			name: 'Created At',
			sortField: 'createdAt',
			selector: (row: DraftContent) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
		},
	]

	const getRouteName = (route: string) => {
		if (!route) return 'LOApply'

		const routes = [
			'Apply',
			'ApplyCoBorrower',
			'ApplySpanish',
			'Prequal',
			'PrequalCoBorrower',
		]
		return routes.includes(route) ? `LO${route}` : route
	}

	const getLoanDraftMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.getLoanDraft(id),
		onSuccess: async (result) => {
			const draft = result?.data
			if (!draft) return
			if (draft.customData) {
				draft.customData = {
					targetHistory: [],
					routeName: getRouteName(draft.customData.routeName),
				}
			}
			ventiState.set(theme.storageKeys.loanDraft, draft)

			navigate(
				`/${navigationLinking[draft?.customData?.routeName as keyof typeof navigationLinking]}`
			)
		},
	})

	const handleRowCompleteClick = async (row: DraftContent) => {
		setFetching(true)
		await getLoanDraftMutation.mutateAsync(row.id)
	}

	const deleteDraftMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.deleteLoanDraft(id),
		onSuccess: async () => {
			alert('The loan draft was successfully deleted', {
				severity: 'success',
			})
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanDrafts],
			})
			setSelectedRow(null)
			setRemoveModalVisible(false)
		},
	})

	const handleDeleteClick = async () => {
		if (!selectedRow) return
		await deleteDraftMutation.mutateAsync(selectedRow.id)
	}

	const handleRowDeleteClick = (row: DraftContent) => {
		setSelectedRow(row)
		setRemoveModalVisible(true)
	}

	const actionItems: ActionItem<DraftContent>[] = [
		{
			name: 'Complete',
			onClick: (e, row) => handleRowCompleteClick(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => handleRowDeleteClick(row),
		},
	]

	return (
		<>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={handleDeleteClick}
				row={selectedRow}
				loading={deleteDraftMutation.isPending}
			/>
			<ModalPleaseWait
				visible={fetching}
				message="Fetching loan information"
			/>
			<DataTable
				data={result?.data?.rows || []}
				columns={columns}
				title="Incomplete loans"
				progressPending={isFetching || isRefetching}
				onRefreshClick={refetch}
				fixedHeader
				actionItems={actionItems}
				onSearchChange={handleSearchChange}
				onSort={handleSort}
				searchText={searchText}
				onClearSearchClick={handleClearSearchClick}
				defaultSortFieldId={sortBy}
				defaultSortAsc={sortDirection === 'asc'}
				onChangePage={handlePageChange}
				onChangeRowsPerPage={handleRowsPerPageChange}
				pagination
				paginationTotalRows={result?.data?.count}
				paginationServer
			/>
		</>
	)
}

export default LoanDraftsTab
