import { getTheme } from '../config'
import { state as ventiState } from 'venti'

const theme = getTheme()

export const host =
	process.env.REACT_APP_POS_API_HOST || 'https://api.thebigpos.com'

/**
 * Roles
 * @type Object
 */
export const Roles = {
	borrower: 'Borrower', // 0
	loanOfficer: 'LoanOfficer', // 1
	admin: 'Admin', // 2
	superAdmin: 'SuperAdmin', // 3
	realtor: 'Realtor', // 4
	settlementAgent: 'SettlementAgent',
	branchManager: 'BranchManager', // 17
	loanProcessor: 'LoanProcessor', // 6
	loanOfficerAssistant: 'LoanOfficerAssistant', // 7
}

/**
 * Site types
 * @type {{realtor: number, corporate: number, admin: number, branch: number, loanOfficer: number}}
 */
export const SiteTypes = {
	admin: 0,
	corporate: 1,
	branch: 2,
	loanOfficer: 3,
	realtor: 4,
}

/**
 * Download form submission file
 * @param {string} formSubmissionId
 * @param {string} formSubmissionFileId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function downloadFormSubmissionFile(
	formSubmissionId,
	formSubmissionFileId,
	siteConfigurationId
) {
	return `${host}/api/form-submissions/${formSubmissionId}/files/${formSubmissionFileId}/download?&accessToken=${ventiState.get(theme.storageKeys.authToken)}&siteConfigurationId=${siteConfigurationId}`
}

export function getLoanDocumentContentUrl({ loanId, documentId }) {
	return `${host}/api/los/loan/${loanId}/document/${documentId}/content?contentType=binary&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}
/**
 * Get loan document url
 * @param {string} loanId
 * @param {string} documentId
 * @returns {Promise}
 */
export function getLoanDocumentUrl(loanId, documentId) {
	return `${host}/api/loans/${loanId}/documents/${documentId}/download?&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}

export function getListingOpenHouseFlyerUrl(id) {
	return `${host}/api/listings/${id}/open-house-flyer`
}
