import { Button } from '../../../../components/Button'
import AssetsGIF from 'assets/lordicons/AssetsAnimated.gif'
import { useAppContext } from '../../../../components/AppContext'
import LoanTaskDocumentForm from '../forms/LoanTaskDocumentForm'
import React from 'react'
import { eventTypes } from '../../../../services/constants'
import { getErrorMessage } from '../../../../services/helper'
import { useVenti } from 'venti'
import { useMixpanel } from '../../../../hooks/useMixpanel'
import { getTheme } from '../../../../config'
import { useAlert } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import useUser from '../../../../hooks/useUser'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'

const theme = getTheme()

export const Verification = ({ onSubmit, onCancel, loanTask }) => {
	const { task } = loanTask

	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { siteConfig } = state
	const { isLoanOfficer } = useUser()

	const isVerificationAndLoanOfficer =
		isLoanOfficer && task.type.includes('Verification')
	const [showUploadForm, setShowUploadForm] = React.useState(
		isVerificationAndLoanOfficer
	)

	const typeMap = {
		VerificationOfAssets: {
			text1: 'Verify Your Financial Assets',
			text2: `You may VERIFY YOUR ASSETS by signing-in to your online banking system and choosing the accounts to share with ${siteConfig.companyName}, or you may upload your bank statements manually.`,
			buttonTitle: `Bank Login`,
			operations: ['voa'],
		},
		VerificationOfIncome: {
			text1: 'Verify Your Income',
			text2: `You may VERIFY YOUR INCOME by signing-in to your employee payroll system and allowing ${siteConfig.companyName} to access this information, or you may upload your pay stubs manually.`,
			buttonTitle: `Payroll Login`,
			operations: ['voi'],
		},
		VerificationOfEmployment: {
			text1: 'Verify Your Employment',
			text2: `You may VERIFY YOUR EMPLOYMENT by signing-in to your employee payroll system and allowing ${siteConfig.companyName} to access this information, or you may upload your pay stubs manually.`,
			buttonTitle: 'Payroll Login',
			operations: ['voe'],
		},
		VerificationOfIncomeAndEmployment: {
			text1: 'Verify Your Income & Employment',
			text2: `You may VERIFY YOUR INCOME AND EMPLOYMENT by signing-in to your employee payroll system and allowing ${siteConfig.companyName} to access this information, or you may choose to upload your pay stubs manually. Additionally, we may call your employer to verify your employment`,
			buttonTitle: 'Payroll Login',
			operations: ['voi', 'voe'],
		},
	}

	const verifyMutation = useMutation({
		mutationFn: async (data) => TheBigPOSApi.verify(data),
	})

	const handleManualUploadClick = () => {
		setShowUploadForm(true)
	}

	const handleVerificationLogin = async () => {
		try {
			ventiState.set(
				theme.storageKeys.pleaseWaitMessage,
				'Please stand by while we contact your local Banks. This may take up to 60 seconds.'
			)

			const verificationRequestData =
				await verifyMutation.mutateAsync({
					loanTaskId: loanTask.id,
					loanID: loanTask.loanID,
					operations: typeMap[task.type]?.operations || 'none',
				})

			const verificationRequest = verificationRequestData?.data

			mixpanel.trackEvent(eventTypes.TASK_VERIFICATION_REQUEST, {
				loanId: loanTask.loanID,
			})

			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(
				theme.storageKeys.verificationRequestId,
				verificationRequest.requestId
			)
			setTimeout(() => {
				if (!verificationRequest?.ssoUrls?.AccountChek) {
					ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
					ventiState.set(theme.storageKeys.errorObject, {
						message:
							'We were unable to connect with your local banks. Please try again later.',
					})
					return
				}
				const popup = window.open(
					verificationRequest.ssoUrls.AccountChek,
					'_blank'
				)
				if (
					!popup ||
					popup.closed ||
					typeof popup.closed === 'undefined'
				) {
					ventiState.set(
						theme.storageKeys.infoMessage,
						'Pop-up Blocker is enabled! Please add this site to your exception list and try again.'
					)
				}
			}, 500)
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	return (
		<div>
			<div className="flex flex-row py-5 pr-5 justify-center items-center">
				<img
					src={siteConfig?.assetsAnimatedIcon || AssetsGIF}
					alt="AssetGif"
					className="h-40"
				/>
				<div>
					<div>
						<p className="text-lg text-slate-500 font-bold text-center">
							{!!task.type && typeMap[task.type]?.text1}
						</p>
						<p className="text-sm text-slate-500 px-3 pt-3 text-center">
							{!!task.type && typeMap[task.type]?.text2}
						</p>
						{!showUploadForm && (
							<p className="text-sm text-slate-500 px-3 pt-3 pb-5 text-center">
								Please make sure to allow pop-ups for this site
							</p>
						)}
					</div>
				</div>
			</div>
			{showUploadForm && (
				<div className="mt-3">
					<LoanTaskDocumentForm
						loanTask={loanTask}
						onSubmit={onSubmit}
						onCancel={onCancel}
					/>
				</div>
			)}
			{!showUploadForm && (
				<div className="grid grid-cols-2 gap-2">
					<Button
						text="Manual Upload"
						onClick={handleManualUploadClick}
						variant="outlined"
					/>
					<Button
						text={
							!!task.type
								? typeMap[task.type]?.buttonTitle
								: 'Verify Me'
						}
						onClick={handleVerificationLogin}
					/>
				</div>
			)}
		</div>
	)
}
